/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FeatureFlagAreEnabledData,
  FeatureFlagAreEnabledPayload,
  FeatureFlagFeatureData,
  FeatureFlagListFeaturesData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FeatureFlag extends HttpClient {
  /**
   * No description
   *
   * @tags FeatureFlag
   * @name FeatureFlagFeature
   * @request GET:/api/FeatureFlag/feature/{key}
   * @secure
   */
  featureFlagFeature = (key: string, params: RequestParams = {}) =>
    this.request<FeatureFlagFeatureData, any>({
      path: `/api/FeatureFlag/feature/${key}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags FeatureFlag
   * @name FeatureFlagAreEnabled
   * @request POST:/api/FeatureFlag/are-enabled
   * @secure
   */
  featureFlagAreEnabled = (data: FeatureFlagAreEnabledPayload, params: RequestParams = {}) =>
    this.request<FeatureFlagAreEnabledData, any>({
      path: `/api/FeatureFlag/are-enabled`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags FeatureFlag
   * @name FeatureFlagListFeatures
   * @request GET:/api/FeatureFlag/list-features
   * @secure
   */
  featureFlagListFeatures = (params: RequestParams = {}) =>
    this.request<FeatureFlagListFeaturesData, any>({
      path: `/api/FeatureFlag/list-features`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    });
}
