/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PlaceAuthorityLookup2Data,
  PlaceAuthorityLookup2Params,
  PlaceAuthorityLookupData,
  PlaceAuthorityReloadData,
  PlaceAuthorityTypeaheadLocationidData,
  PlaceAuthorityTypeaheadReloadData,
  PlaceAuthorityTypeaheadSearchData,
  PlaceAuthorityTypeaheadSearchPageData
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class PlaceAuthority extends HttpClient {
  /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityLookup
   * @request GET:/api/PlaceAuthority/lookup/{locationId}
   * @secure
   */
  placeAuthorityLookup = (locationId: string, params: RequestParams = {}) =>
    this.request<PlaceAuthorityLookupData, any>({
      path: `/api/PlaceAuthority/lookup/${locationId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityLookup2
   * @request GET:/api/PlaceAuthority/lookup
   * @originalName placeAuthorityLookup
   * @duplicate
   * @secure
   */
  placeAuthorityLookup2 = (query: PlaceAuthorityLookup2Params, params: RequestParams = {}) =>
    this.request<PlaceAuthorityLookup2Data, any>({
      path: `/api/PlaceAuthority/lookup`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityReload
   * @request GET:/api/PlaceAuthority/reload
   * @secure
   */
  placeAuthorityReload = (params: RequestParams = {}) =>
    this.request<PlaceAuthorityReloadData, any>({
      path: `/api/PlaceAuthority/reload`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityTypeaheadLocationid
   * @request GET:/api/PlaceAuthority/typeahead/locationid/{searchString}
   * @secure
   */
  placeAuthorityTypeaheadLocationid = (searchString: string, params: RequestParams = {}) =>
    this.request<PlaceAuthorityTypeaheadLocationidData, any>({
      path: `/api/PlaceAuthority/typeahead/locationid/${searchString}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityTypeaheadSearch
   * @request GET:/api/PlaceAuthority/typeahead/search/{searchString}
   * @secure
   */
  placeAuthorityTypeaheadSearch = (searchString: string, params: RequestParams = {}) =>
    this.request<PlaceAuthorityTypeaheadSearchData, any>({
      path: `/api/PlaceAuthority/typeahead/search/${searchString}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityTypeaheadSearchPage
   * @request GET:/api/PlaceAuthority/typeahead/search/{requestId}/page/{pageNumber}/{searchString}
   * @secure
   */
  placeAuthorityTypeaheadSearchPage = (
    requestId: string,
    pageNumber: number,
    searchString: string,
    params: RequestParams = {}
  ) =>
    this.request<PlaceAuthorityTypeaheadSearchPageData, any>({
      path: `/api/PlaceAuthority/typeahead/search/${requestId}/page/${pageNumber}/${searchString}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags PlaceAuthority
   * @name PlaceAuthorityTypeaheadReload
   * @request GET:/api/PlaceAuthority/typeahead/reload
   * @secure
   */
  placeAuthorityTypeaheadReload = (params: RequestParams = {}) =>
    this.request<PlaceAuthorityTypeaheadReloadData, any>({
      path: `/api/PlaceAuthority/typeahead/reload`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
