
import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { FIRST_STORY_PROMPT_OPTION_SELECTED } from "redux/constants/actionTypes";
import { removeItemFromWidgetSettings, shuffleArray } from "utils";
import { v4 as uuidv4 } from "uuid";
import {
  UPDATE_PROMPTS_GROUP,
  UPDATE_PROMPTS_SEARCH,
  UPDATE_PROMPTS_TELL_STORY,
  GROUP_PROMPT_HOMEPAGE,
  UPDATE_PROMPTS_FREE_TRIAL,
  STORY_STARTERS_TEXT,
} from "../constants";
import * as API_URLS from "../constants/apiUrl";
import { callApi } from "../utils";
import { REACT_APP_CLIENT_ID } from "utils/env";
import { getGoogleSessionId } from "services";

interface Header {
  "wa-clientId": string;
  "wa-requestId": string;
  "wa-sessionId": string;
}

const createHeader = (): Header => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId(),
  };
};

export const dismissFreeTrialPrompt = (): ThunkAction<void, any, unknown, AnyAction> => {
  return (dispatch: Dispatch) => {
    const url = API_URLS.dismissFreeTrialPromptAPI;
    const staticHeader = createHeader();
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        dispatch({
          type: UPDATE_PROMPTS_FREE_TRIAL,
          payload: false,
        });
      })
      .catch((err: Error) => {
        console.error("error", err instanceof Error ? err.message : String(err));
      });
  };
};

export const dismissSearchPrompt = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    const url = API_URLS.dismissSearchPromptAPI;
    const staticHeader = createHeader();
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        removeItemFromWidgetSettings(getState().user.widgetDisplayOrder, "Search");
        dispatch({
          type: UPDATE_PROMPTS_SEARCH,
          payload: false,
        });
      })
      .catch((err: Error) => {
        console.error("error", err instanceof Error ? err.message : String(err));
      });
  };
};

export const dismissTellAStoryPrompt = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    const url = API_URLS.dismissStoryPromptAPI;
    const staticHeader = createHeader();
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        removeItemFromWidgetSettings(getState().user.widgetDisplayOrder, "Story");
        dispatch({
          type: UPDATE_PROMPTS_TELL_STORY,
          payload: false,
        });
      })
      .catch((err: Error) => {
        console.error("error", err instanceof Error ? err.message : String(err));
      });
  };
};

export const dismissGroupPrompt = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    const url = API_URLS.dismissGroupPromptAPI;
    const staticHeader = createHeader();
    callApi("POST", url, {}, false, staticHeader)
      .then((_res) => {
        removeItemFromWidgetSettings(getState().user.widgetDisplayOrder, "Group");
        dispatch({
          type: UPDATE_PROMPTS_GROUP,
          payload: false,
        });
      })
      .catch((err: Error) => {
        console.error("error", err instanceof Error ? err.message : String(err));
      });
  };
};

export const openCloseGroupModalViaHomepagePrompt = (bool: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GROUP_PROMPT_HOMEPAGE,
      payload: bool,
    });
  };
};

export const tellStoryPromptSelected = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: FIRST_STORY_PROMPT_OPTION_SELECTED.SUCCESS,
      payload: data,
    });
  };
};

export const storyStartersTexts = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    const url = API_URLS.getStoryStartedTexts;
    const staticHeader = createHeader();
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch({
          type: STORY_STARTERS_TEXT,
          payload: shuffleArray(res?.data),
        });
        return res.data;
      })
      .catch((err: Error) => {
        console.error("error", err instanceof Error ? err.message : String(err));
      });
  };
};
