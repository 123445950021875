import API from "API/__generated__";
import _ from "lodash";
import { personInformation } from "redux/constants/apiUrl";
import { trimString } from "shared-logics";
import { relation } from "../../data/person";
import {
  ADD_LIFEEVENT,
  ADD_PERSON_HERO_THUMBNAIL,
  ADD_PET_HERO_THUMBNAIL,
  CLEAR_IMAGE,
  CLEAR_REFETCH_INFO,
  DELETE,
  DELETED_EVENT,
  DELETE_PERSON_LOADED,
  DELETE_PERSON_LOADING,
  DELETE_PET_BG_IMAGE,
  DELETING_EVENT,
  ERROR_ALLLIFEEVENTS,
  ERROR_PARENTS,
  ERROR_SPOUSES,
  FAIL_LIFEEVENT,
  GET,
  GET_EVENTS,
  GET_LIFEEVENTS,
  GET_PARENTS,
  GET_PERSONAL_INFO,
  GET_SPOUSES,
  NOT_FOUND_ERROR,
  PERSON_ERROR,
  PERSON_PAGE_LOADING,
  POST,
  PROFILE_IMAGE_LOADING,
  PUT,
  REFETCH_FAMILY_INFO,
  REFETCH_FAMILY_INFO_COMPLETE,
  REFETCH_PERSON_BASIC_INFO,
  REFETCH_PERSON_INFO,
  SAVE_HERO_IMAGE,
  SUCCESS_LIFEEVENT,
  THUMBNAIL_UPLOAD_COMPLETE,
  TREE_PROGRESS_PAYLOAD,
  UPDATE_EVENTS,
  UPDATE_LIFE_EVENTS,
  UPDATE_PARENTS,
  UPDATE_PARENTS_AND_SIBLINGS,
  UPDATE_PERSONAL_INFO,
  UPDATE_SPOUSES,
  UPDATE_SPOUSES_AND_CHILDREN,
  WHOLE_REFETCHED
} from "../constants";
import {
  addPotentialPersonPayload,
  createUID,
  getGlobalPersonPageError,
  getPersonPageError,
  heroImageEditPayload,
  heroImageUploadPayload
} from "../helpers";
import {
  getOptimisticDetails,
  updateFamilyOnPotentialParentAcceptance,
  updateFamilyOnPotentialParentReject
} from "../helpers/optimisticPersonPage";
import {
  getApiType,
  getApiTypeByEventName,
  getApiTypeForLifeEvents,
  getEventsPayload,
  getLifeEventsPayload,
  getNewDataPayload,
  getNewDataPayloadForDeleteEvent,
  getParentsAndSiblingsPayload,
  getParsedLifeEvents,
  getPayloadForDeleteEvent,
  getPayloadForNewEvent,
  getPayloadForNewRelationshipEvent,
  getSpousalPayloadForDeleteEvent,
  getSpousesAndChildrenPayload,
  isSpousalEvent
} from "../helpers/personPayloads";
import { apiRequest } from "../requests";
import { actionCreator } from "../utils";
import * as CONSTANTS from "./../constants/actionTypes";
import {
  computeTreeData,
  getPreferenceDetail,
  getPreferenceDetailFocus,
  getPreferenceRelation
} from "./family";
import { addMessage } from "./toastr";
export const getPerson = (treeId, primaryPersonId) => async (dispatch) => {
  try {
    dispatch({ type: PERSON_PAGE_LOADING });
    const handleGlobalErr = getGlobalPersonPageError();
    const handlePageError = getPersonPageError();

    const personalInfo = await apiRequest(
      GET,
      `persons/${primaryPersonId}/info`,
      undefined,
      undefined,
      undefined,
      undefined,
      handleGlobalErr
    );
    const result = { data: { personalInfo: personalInfo.data, relation } };
    if (personalInfo.data) dispatch({ type: GET_PERSONAL_INFO, payload: result.data });
    const eventsInfo = await apiRequest(GET, `persons/${treeId}/${primaryPersonId}/lifeevents`);
    if (eventsInfo.data) dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
    else if (eventsInfo?.status === 500) {
      dispatch({ type: GET_EVENTS, payload: [] });
    }
    const spousesAndChildren = await apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/spouseswithchildren?addUnknownParents=1`,
      undefined,
      undefined,
      undefined,
      undefined,
      handlePageError
    );
    if (spousesAndChildren?.status === 500) {
      dispatch({ type: ERROR_SPOUSES });
      dispatch({ type: GET_SPOUSES, payload: [] });
    } else if (spousesAndChildren.data)
      dispatch({ type: GET_SPOUSES, payload: spousesAndChildren.data.Spouses });
    const parentsAndSiblings = await apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/parentswithsiblings?addUnknownParents=1`,
      undefined,
      undefined,
      undefined,
      undefined,
      handlePageError
    );
    if (parentsAndSiblings?.status === 500) {
      dispatch({ type: ERROR_PARENTS });
    } else if (parentsAndSiblings.data)
      dispatch({ type: GET_PARENTS, payload: parentsAndSiblings.data });
    const lifeEvents = await apiRequest(
      GET,
      `persons/${primaryPersonId}/alllifeevents?${treeId}`,
      undefined,
      undefined,
      undefined,
      undefined,
      handlePageError
    );
    if (lifeEvents?.status === 500) {
      dispatch({ type: ERROR_ALLLIFEEVENTS });
    }
    const parsedLifeEvents = getParsedLifeEvents(lifeEvents);
    if (lifeEvents.data) dispatch({ type: GET_LIFEEVENTS, payload: parsedLifeEvents });
  } catch (err) {
    if (err.response.status === 404) {
      dispatch({
        type: NOT_FOUND_ERROR,
        payload: true
      });
    }
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateParents = (treeId, primaryPersonId) => async (dispatch) => {
  try {
    const parentsAndSiblings_res = await apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/parentswithsiblings?addUnknownParents=1`
    );
    const eventsInfo = await apiRequest(GET, `persons/${treeId}/${primaryPersonId}/lifeevents`);
    const res = {
      data: {
        relatedParentIds: parentsAndSiblings_res.data.RelatedParentIds,
        filialRelationshipId: parentsAndSiblings_res.data.FilialRelationshipId,
        parentsAndSiblings: parentsAndSiblings_res.data.ParentsAndSiblings
      }
    };

    dispatch({
      type: UPDATE_PARENTS,
      payload: res.data
    });
    dispatch({
      type: UPDATE_EVENTS,
      payload: eventsInfo.data.Events
    });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateSpouses = (treeId, primaryPersonId) => async (dispatch) => {
  try {
    const res = await apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/spouseswithchildren?addUnknownParents=1`
    );

    dispatch({
      type: UPDATE_SPOUSES,
      payload: res.data.Spouses
    });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const updatePersonalInfo =
  (oldPersonalInfo, newPersonalInfo, changedKey, person, onUpdatePersonalInfo) =>
  async (dispatch) => {
    try {
      const payload = {
        id: newPersonalInfo.id,
        givenName: {
          nameAssertionId: createUID(newPersonalInfo.nameAssertionId),
          evidence: oldPersonalInfo.givenName.evidence,
          givenName:
            changedKey === "givenName" || changedKey === "name"
              ? trimString(newPersonalInfo.givenName)
              : oldPersonalInfo.givenName.givenName
        },
        alternateGivenNames: oldPersonalInfo.alternateGivenNames,
        surname: {
          nameAssertionId: createUID(newPersonalInfo.nameAssertionId),
          evidence: oldPersonalInfo.surname.evidence,
          surname:
            changedKey === "surname" || changedKey === "name"
              ? trimString(newPersonalInfo.surname)
              : oldPersonalInfo.surname.surname
        },
        alternateSurnames: oldPersonalInfo.alternateSurnames,
        gender: {
          assertionId: createUID(oldPersonalInfo.gender.assertionId),
          evidence: oldPersonalInfo.gender.evidence,
          gender: changedKey === "gender" ? newPersonalInfo.gender : oldPersonalInfo.gender.gender
        }
      };

      const newData = {
        ...newPersonalInfo,
        givenName:
          changedKey === "givenName" || changedKey === "name"
            ? trimString(newPersonalInfo.givenName)
            : oldPersonalInfo.givenName.givenName,
        surname:
          changedKey === "surname" || changedKey === "name"
            ? trimString(newPersonalInfo.surname)
            : oldPersonalInfo.surname.surname
      };

      if (newPersonalInfo.alternate) {
        const newPayload = _.cloneDeep(payload);
        newPayload.givenName.givenName = oldPersonalInfo.givenName.givenName;
        newPayload.surname.surname = oldPersonalInfo.surname.surname;
        const alternateGivenName = newPayload.alternateGivenNames.find(
          (x) => x.nameAssertionId === newPersonalInfo.nameAssertionId
        );
        if (alternateGivenName) {
          alternateGivenName.givenName = payload.givenName.givenName;
        }
        const alternateSurname = newPayload.alternateSurnames.find(
          (x) => x.nameAssertionId === newPersonalInfo.nameAssertionId
        );
        if (alternateSurname) {
          alternateSurname.surname = payload.surname.surname;
        }

        dispatch({ type: UPDATE_PERSONAL_INFO, payload: newPayload });
      } else {
        dispatch({ type: UPDATE_PERSONAL_INFO, payload });
      }

      let refetched = false;

      switch (changedKey) {
        case "name":
          refetched = await API.Persons.personsGivenname({
            treeId: oldPersonalInfo.treeId,
            id: payload.id,
            nameAssertionId: payload.givenName.nameAssertionId,
            givenName: payload.givenName.givenName
          });
          refetched ||= await API.Persons.personsSurname({
            treeId: oldPersonalInfo.treeId,
            id: payload.id,
            nameAssertionId: payload.givenName.nameAssertionId,
            surname: payload.surname.surname
          });

          break;
        case "givenName":
          refetched = await apiRequest(PUT, `Persons/givenname`, {
            treeId: oldPersonalInfo.treeId,
            id: payload.id,
            nameAssertionId: payload.givenName.nameAssertionId,
            givenName: payload.givenName.givenName
          });
          break;

        case "surname":
          refetched = await apiRequest(PUT, `Persons/surname`, {
            treeId: oldPersonalInfo.treeId,
            id: payload.id,
            nameAssertionId: payload.surname.nameAssertionId,
            surname: payload.surname.surname
          });
          break;

        case "gender":
          refetched = await apiRequest(PUT, `Persons/gender`, {
            treeId: oldPersonalInfo.treeId,
            personId: payload.id,
            genderAssertionId: payload.gender.assertionId,
            newGender: payload.gender.gender
          });
          break;

        default:
          break;
      }
      onUpdatePersonalInfo(newPersonalInfo);
      if (refetched) {
        const optimisticDetails = getOptimisticDetails(newData, changedKey, person);
        dispatch({
          type: WHOLE_REFETCHED,
          payload: optimisticDetails
        });
      }
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

const apisForPersonTables = async (apiType, payload) => {
  let refetched = false,
    newAge;

  switch (apiType) {
    // Family Table, Events Table
    case "name":
      refetched = true;
      await apiRequest(PUT, `Persons/givenname`, {
        treeId: payload.treeId,
        id: payload.personId,
        nameAssertionId: payload.AssertionId,
        givenName: trimString(payload.firstName)
      });
      await apiRequest(PUT, `Persons/surname`, {
        treeId: payload.treeId,
        id: payload.personId,
        nameAssertionId: payload.AssertionId,
        surname: trimString(payload.lastName)
      });
      return { refetched };

    case "firstName":
      refetched = true;
      await apiRequest(PUT, `Persons/givenname`, {
        treeId: payload.treeId,
        id: payload.personId,
        nameAssertionId: payload.AssertionId,
        givenName: trimString(payload.firstName)
      });
      return { refetched };

    case "lastName":
      refetched = true;
      await apiRequest(PUT, `Persons/surname`, {
        treeId: payload.treeId,
        id: payload.personId,
        nameAssertionId: payload.AssertionId,
        surname: trimString(payload.lastName)
      });
      return { refetched };

    case "gender":
      refetched = true;
      await apiRequest(PUT, `Persons/gender`, {
        treeId: payload.treeId,
        personId: payload.personId,
        genderAssertionId: payload.AssertionId,
        newGender: payload.gender
      });
      return { refetched };

    case "birthdate":
      refetched = true;
      newAge = await apiRequest(PUT, `Persons/birthdate`, {
        treeId: payload.treeId,
        personId: payload.personId,
        birthAssertionId: payload.AssertionId,
        newDate: trimString(payload.date)
      });
      return { refetched, newAge: newAge.data };

    case "birthlocation":
      refetched = true;
      await apiRequest(PUT, `Persons/birthlocation`, {
        treeId: payload.treeId,
        personId: payload.personId,
        birthAssertionId: payload.AssertionId,
        newLocation: trimString(payload.location),
        newLocationId: payload.locationId
      });
      return { refetched };

    case "deathdate":
      refetched = true;
      newAge = await apiRequest(PUT, `Persons/deathdate`, {
        treeId: payload.treeId,
        personId: payload.personId,
        deathAssertionId: payload.AssertionId,
        newDate: trimString(payload.date)
      });
      return { refetched, newAge: newAge.data };

    case "deathlocation":
      refetched = true;
      await apiRequest(PUT, `Persons/deathlocation`, {
        treeId: payload.treeId,
        personId: payload.personId,
        deathAssertionId: payload.AssertionId,
        newLocation: trimString(payload.location),
        newLocationId: payload.locationId
      });
      return { refetched };

    case "marriagedate":
      refetched = true;
      await apiRequest(PUT, `Persons/marriagedate`, {
        treeId: payload.treeId,
        personId: payload.personId,
        marriageAssertionId: payload.AssertionId,
        spousalRelationshipId: payload.SpousalRelationshipId,
        newDate: trimString(payload.date)
      });
      return { refetched };

    case "marriagelocation":
      refetched = true;
      await apiRequest(PUT, `Persons/marriagelocation`, {
        treeId: payload.treeId,
        personId: payload.personId,
        marriageAssertionId: payload.AssertionId,
        spousalRelationshipId: payload.SpousalRelationshipId,
        newLocation: trimString(payload.location),
        newLocationId: payload.locationId
      });
      return { refetched };

    case "divorcedate":
      refetched = true;
      await apiRequest(PUT, `Persons/divorcedate`, {
        treeId: payload.treeId,
        personId: payload.personId,
        divorceAssertionId: payload.AssertionId,
        spousalRelationshipId: payload.SpousalRelationshipId,
        newDate: trimString(payload.date)
      });
      return { refetched };

    case "divorcelocation":
      refetched = true;
      await apiRequest(PUT, `Persons/divorcelocation`, {
        treeId: payload.treeId,
        personId: payload.personId,
        divorceAssertionId: payload.AssertionId,
        spousalRelationshipId: payload.SpousalRelationshipId,
        newLocation: trimString(payload.location),
        newLocationId: payload.locationId
      });
      return { refetched };

    case "spousallocation":
      refetched = true;
      await apiRequest(PUT, `Persons/spousallocation`, {
        treeId: payload.treeId,
        personId: payload.personId,
        assertionId: payload.AssertionId,
        spousalRelationshipId: payload.SpousalRelationshipId,
        newLocation: trimString(payload.location),
        assertionType: payload.eventType,
        newLocationId: payload.locationId
      });
      return { refetched };

    case "spousaldate":
      refetched = true;
      newAge = await apiRequest(PUT, `Persons/spousaldate`, {
        treeId: payload.treeId,
        personId: payload.personId,
        assertionId: payload.AssertionId,
        spousalRelationshipId: payload.SpousalRelationshipId,
        newDate: trimString(payload.date),
        assertionType: payload.eventType
      });

      return { refetched, newAge: newAge.data };

    // All Life Events Table
    case "date":
      refetched = true;
      newAge = await apiRequest(PUT, `Persons/date`, {
        treeId: payload.treeId,
        personId: payload.personId,
        dateAssertionId: payload.AssertionId,
        newDate: trimString(payload.date),
        eventType: payload.eventType
      });
      return { refetched, newAge: newAge.data };

    case "location":
      refetched = true;
      await apiRequest(PUT, `Persons/location`, {
        treeId: payload.treeId,
        personId: payload.personId,
        locationAssertionId: payload.AssertionId,
        newLocation: trimString(payload.location),
        eventType: payload.eventType,
        newLocationId: payload.locationId
      });
      return { refetched };

    default:
      return { refetched };
  }
};

export const updateSpousesAndChildren =
  (oldData, newData, changedKey, treeId, person, onUpdateSpousesAndChildren) =>
  async (dispatch) => {
    try {
      const { localPayload, payload } = getSpousesAndChildrenPayload(
        oldData,
        newData,
        changedKey,
        treeId
      );

      dispatch({ type: UPDATE_SPOUSES_AND_CHILDREN, payload: localPayload });

      const apiType = getApiType(changedKey);

      const refetched = await apisForPersonTables(apiType, payload);
      onUpdateSpousesAndChildren(newData);
      if (refetched) {
        const optimisticDetails = getOptimisticDetails(
          newData,
          changedKey,
          person,
          payload.AssertionId
        );
        if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
      }
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

export const updateParentsAndSiblings =
  (oldData, newData, changedKey, treeId, person, onUpdatePersonFromFamilyTable) =>
  async (dispatch) => {
    try {
      const { localPayload, payload } = getParentsAndSiblingsPayload(
        oldData,
        newData,
        changedKey,
        treeId
      );
      dispatch({ type: UPDATE_PARENTS_AND_SIBLINGS, payload: localPayload });

      const apiType = getApiType(changedKey);

      const { refetched, newAge } = await apisForPersonTables(apiType, payload);
      onUpdatePersonFromFamilyTable(newData);
      if (changedKey === "death" || changedKey === "birth") {
        newData.age = newAge.toString();
      }

      if (refetched) {
        if (newData.id === person.personalInfo.id && changedKey === "birth") {
          const lifeEvents = await apiRequest(
            GET,
            `persons/${person.personalInfo.id}/alllifeevents?${treeId}`
          );
          const eventsInfo = await apiRequest(
            GET,
            `persons/${treeId}/${person.personalInfo.id}/lifeevents`
          );
          dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
          const personalInfo = await apiRequest(GET, `persons/${person.personalInfo.id}/info`);
          const result = { data: { personalInfo: personalInfo.data, relation } };
          if (personalInfo.data) dispatch({ type: GET_PERSONAL_INFO, payload: result.data });
          let personData = {
            ...person,
            personalInfo: result.data.personalInfo,
            events: eventsInfo.data.Events,
            lifeEvents: lifeEvents.data
          };

          const optimisticDetails = getOptimisticDetails(
            newData,
            changedKey,
            personData,
            payload.AssertionId
          );
          if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
        } else {
          const optimisticDetails = getOptimisticDetails(
            newData,
            changedKey,
            person,
            payload.AssertionId
          );
          if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
        }
      }
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

export const updateEvents = (oldData, newData, changedKey, treeId, person) => async (dispatch) => {
  try {
    const { localPayload, payload } = getEventsPayload(oldData, newData, changedKey, treeId);

    dispatch({ type: UPDATE_EVENTS, payload: localPayload });

    const apiType = getApiTypeByEventName(newData.name, changedKey);

    const { refetched, newAge } = await apisForPersonTables(apiType, payload);

    if (refetched) {
      if (changedKey === "date") {
        newData.age = newAge.toString();
      }
      if (newData.name === "Birth") {
        const lifeEvents = await apiRequest(
          GET,
          `persons/${person.personalInfo.id}/alllifeevents?${treeId}`
        );
        const eventsInfo = await apiRequest(
          GET,
          `persons/${treeId}/${person.personalInfo.id}/lifeevents`
        );
        dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
        const personalInfo = await apiRequest(GET, `persons/${person.personalInfo.id}/info`);
        const result = { data: { personalInfo: personalInfo.data, relation } };
        if (personalInfo.data) dispatch({ type: GET_PERSONAL_INFO, payload: result.data });
        let personData = {
          ...person,
          personalInfo: result.data.personalInfo,
          events: eventsInfo.data.Events,
          lifeEvents: lifeEvents.data
        };

        const optimisticDetails = getOptimisticDetails(
          newData,
          changedKey,
          personData,
          payload.AssertionId
        );
        if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
      } else {
        const optimisticDetails = getOptimisticDetails(
          newData,
          changedKey,
          person,
          payload.AssertionId
        );
        if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
      }
    }
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateLifeEvents =
  (oldData, newData, changedKey, treeId, person) => async (dispatch) => {
    try {
      const { localPayload, payload } = getLifeEventsPayload(oldData, newData, changedKey, treeId);
      dispatch({ type: UPDATE_LIFE_EVENTS, payload: localPayload });

      const apiType = getApiTypeForLifeEvents(newData.type, changedKey);

      const { refetched, newAge } = await apisForPersonTables(apiType, payload);

      if (refetched) {
        const lifeEvents = await apiRequest(
          GET,
          `persons/${person.personalInfo.id}/alllifeevents?${treeId}`
        );
        if (changedKey === "date") {
          newData.age = newAge.toString();
        }
        if (
          newData.type === "Burial" ||
          newData.type === "Cremation" ||
          newData.type === "Funeral" ||
          newData.type === "Probate" ||
          newData.type === "Will"
        ) {
          let personData = {
            ...person,
            lifeEvents: lifeEvents.data
          };
          const optimisticDetails = getOptimisticDetails(newData, "updateLifeEvent", personData);
          if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
        } else if (newData.type === "Birth") {
          const eventsInfo = await apiRequest(
            GET,
            `persons/${treeId}/${person.personalInfo.id}/lifeevents`
          );
          dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
          const personalInfo = await apiRequest(GET, `persons/${person.personalInfo.id}/info`);
          const result = { data: { personalInfo: personalInfo.data, relation } };
          if (personalInfo.data) dispatch({ type: GET_PERSONAL_INFO, payload: result.data });
          let personData = {
            ...person,
            personalInfo: result.data.personalInfo,
            events: eventsInfo.data.Events,
            lifeEvents: lifeEvents.data
          };
          const optimisticDetails = getOptimisticDetails(
            newData,
            changedKey,
            personData,
            payload.AssertionId
          );
          if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
        } else {
          const optimisticDetails = getOptimisticDetails(
            newData,
            changedKey,
            person,
            payload.AssertionId
          );
          if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
        }
      }
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

export const refetchPersonInfo = (treeId, primaryPersonId) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_IMAGE_LOADING });
    const personalInfo = apiRequest(GET, `persons/${primaryPersonId}/info`);
    const parentsAndSiblings = apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/parentswithsiblings?addUnknownParents=1`
    );
    const [personalInfo_res, parentsAndSiblings_res] = await Promise.all([
      personalInfo,
      parentsAndSiblings
    ]);

    const res = {
      data: {
        personalInfo: personalInfo_res.data,
        parentsAndSiblings: parentsAndSiblings_res.data.ParentsAndSiblings
      }
    };
    dispatch({
      type: REFETCH_PERSON_INFO,
      payload: res.data
    });
    dispatch({ type: CLEAR_IMAGE });
    dispatch({ type: CLEAR_REFETCH_INFO });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const refetchPersonBasicInfo = (primaryPersonId, refetchCheck) => async (dispatch) => {
  try {
    if (!refetchCheck) dispatch({ type: PROFILE_IMAGE_LOADING });
    const personalInfo = apiRequest(GET, `persons/${primaryPersonId}/info`);
    const [personalInfo_res] = await Promise.all([personalInfo]);

    const res = {
      data: {
        personalInfo: personalInfo_res.data
      }
    };
    dispatch({
      type: REFETCH_PERSON_BASIC_INFO,
      payload: res.data
    });
    dispatch({ type: CLEAR_IMAGE });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};
const commonFunctionToParseEvents = (lifeEvents, oldData) => {
  return lifeEvents.data.reduce((res, ele) => {
    const existed = oldData.find((e) => e.id === ele.id);
    if (ele.type === "Birth") res.push({ ...ele, newRow: false });
    else if (existed) res.push({ ...ele, newRow: false });
    else res.push({ ...ele, newRow: true });
    return res;
  }, []);
};
export const addLifeEvent = (treeId, treePersonId, data, oldData, person) => async (dispatch) => {
  try {
    const payload = getPayloadForNewEvent(treeId, treePersonId, data);
    const newDataPayload = getNewDataPayload(payload, treeId);
    dispatch({ type: ADD_LIFEEVENT });

    await apiRequest(POST, `Persons/lifeevent`, payload);

    const lifeEvents = await apiRequest(GET, `persons/${treePersonId}/alllifeevents?${treeId}`);

    const parsedLifeEvents = commonFunctionToParseEvents(lifeEvents, oldData);

    setTimeout(() => {
      dispatch({ type: SUCCESS_LIFEEVENT, payload: parsedLifeEvents });
    }, 1000);

    const parsedLifeEventsAgain = getParsedLifeEvents(lifeEvents);

    if (
      payload.assertionType.includes("Burial") ||
      payload.assertionType.includes("Cremation") ||
      payload.assertionType.includes("Funeral") ||
      payload.assertionType.includes("Probate") ||
      payload.assertionType.includes("Will")
    ) {
      let personData = {
        ...person,
        lifeEvents: parsedLifeEvents
      };
      if (lifeEvents && person.parentsAndSiblings.length !== 0) {
        const optimisticDetails = getOptimisticDetails(newDataPayload, "addLifeEvent", personData);
        if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
      }
    }

    if (
      payload.assertionType.includes("Birth") ||
      payload.assertionType.includes("Death") ||
      payload.assertionType.includes("Marriage") ||
      payload.assertionType.includes("Divorce")
    ) {
      const eventsInfo = await apiRequest(GET, `persons/${treeId}/${treePersonId}/lifeevents`);
      dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
      const personalInfo = await apiRequest(GET, `persons/${treePersonId}/info`);
      const result = { data: { personalInfo: personalInfo.data, relation } };
      if (personalInfo.data) dispatch({ type: GET_PERSONAL_INFO, payload: result.data });
      let personData = {
        ...person,
        personalInfo: result.data.personalInfo,
        events: eventsInfo.data.Events,
        lifeEvents: parsedLifeEvents
      };
      if (lifeEvents && person.parentsAndSiblings !== []) {
        const optimisticDetails = getOptimisticDetails(newDataPayload, "addLifeEvent", personData);
        if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
      }
    }

    setTimeout(() => {
      dispatch({ type: SUCCESS_LIFEEVENT, payload: parsedLifeEventsAgain });
    }, 6000);
  } catch (err) {
    dispatch({ type: FAIL_LIFEEVENT });
  }
};

//To add relationshipevent via modal
export const addRelationshipEvent =
  (treeId, treePersonId, data, oldData, person, eventName) => async (dispatch) => {
    try {
      const payload = getPayloadForNewRelationshipEvent(treeId, treePersonId, data, eventName);
      const newDataPayload = getNewDataPayload(payload, treeId, data.spouseId);

      dispatch({ type: ADD_LIFEEVENT });
      const apiResult = await apiRequest(POST, `Persons/spousalrelationshipevent`, payload);

      const lifeEvents = await apiRequest(GET, `persons/${treePersonId}/alllifeevents?${treeId}`);

      const parsedLifeEvents = commonFunctionToParseEvents(lifeEvents, oldData);

      setTimeout(() => {
        dispatch({ type: SUCCESS_LIFEEVENT, payload: parsedLifeEvents });
      }, 1000);

      const parsedLifeEventsAgain = getParsedLifeEvents(lifeEvents);

      if (
        payload.assertionType.includes("Birth") ||
        payload.assertionType.includes("Death") ||
        payload.assertionType.includes("Marriage") ||
        payload.assertionType.includes("Divorce")
      ) {
        const eventsInfo = await apiRequest(GET, `persons/${treeId}/${treePersonId}/lifeevents`);
        dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
        const personalInfo = await apiRequest(GET, `persons/${treePersonId}/info`);
        const result = { data: { personalInfo: personalInfo.data, relation } };
        const spousesAndChildren = await apiRequest(
          GET,
          `persons/${treeId}/${treePersonId}/spouseswithchildren?addUnknownParents=1`
        );
        dispatch({ type: UPDATE_SPOUSES_AND_CHILDREN, payload: spousesAndChildren.data.Spouses });
        const parentsAndSiblings = await apiRequest(
          GET,
          `persons/${treeId}/${treePersonId}/parentswithsiblings?addUnknownParents=1`
        );
        if (parentsAndSiblings.data)
          dispatch({ type: GET_PARENTS, payload: parentsAndSiblings.data });
        if (personalInfo.data) dispatch({ type: GET_PERSONAL_INFO, payload: result.data });
        let personData = {
          ...person,
          personalInfo: result.data.personalInfo,
          parentsAndSiblings: parentsAndSiblings.data.ParentsAndSiblings,
          spousesAndChildren: spousesAndChildren.data.Spouses,
          events: eventsInfo.data.Events,
          lifeEvents: parsedLifeEvents
        };
        if (lifeEvents && !payload.spouse.id) {
          const optimisticDetails = getOptimisticDetails(
            newDataPayload,
            "addLifeEvent",
            personData
          );
          if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
        }
      }

      setTimeout(() => {
        dispatch({ type: SUCCESS_LIFEEVENT, payload: parsedLifeEventsAgain });
      }, 2000);

      if (payload.spouse.id) {
        setTimeout(async () => {
          const spousesAndChildren = await apiRequest(
            GET,
            `persons/${treeId}/${treePersonId}/spouseswithchildren?addUnknownParents=1`
          );
          dispatch({ type: UPDATE_SPOUSES_AND_CHILDREN, payload: spousesAndChildren.data.Spouses });
        }, 6000);
      }

      if (payload.spouse.id && apiResult && apiResult.status === 200) {
        let url = `/family/person-page/details/${treeId}/${payload.spouse.id}`;
        setTimeout(() => {
          dispatch(
            addMessage(
              `${payload.spouse.givenName} ${payload.spouse.surname} has been saved.`,
              "success",
              { url }
            )
          );
        }, 2000);
      }
    } catch (err) {
      dispatch({ type: FAIL_LIFEEVENT });
    }
  };

export const addHeroImage = (imagePayload, FF_HandleDerivedImagesAtBE) => async (dispatch) => {
  try {
    let url;
    if (imagePayload?.petId) {
      url = `Media/uploadpetbackgroundimage`;
      dispatch({
        type: ADD_PET_HERO_THUMBNAIL,
        payload: imagePayload
      });
    } else {
      url = `Media/uploadbackgroundimage`;
      dispatch({
        type: ADD_PERSON_HERO_THUMBNAIL,
        payload: imagePayload
      });
    }
    dispatch({
      type: THUMBNAIL_UPLOAD_COMPLETE
    });
    const payload = heroImageUploadPayload(imagePayload, FF_HandleDerivedImagesAtBE);
    await apiRequest(POST, url, payload);
    dispatch({ type: SAVE_HERO_IMAGE });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const editHeroImage = (imagePayload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PERSON_HERO_THUMBNAIL,
      payload: imagePayload
    });

    dispatch({
      type: THUMBNAIL_UPLOAD_COMPLETE
    });
    const payload = heroImageEditPayload(imagePayload);
    if (imagePayload?.petId) {
      await apiRequest(POST, `Media/editpetbackgroundimage`, payload);
    } else {
      await apiRequest(POST, `Media/editbackgroundimage`, payload);
    }
    dispatch({ type: SAVE_HERO_IMAGE });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const deleteHeroImage = (individualId, petId) => async (dispatch) => {
  try {
    if (petId) {
      await apiRequest(DELETE, `Pet/deletepetbackgroundimage/${individualId}`);
      dispatch({ type: DELETE_PET_BG_IMAGE });
    } else {
      await apiRequest(DELETE, `Persons/deletebackgroundimage/${individualId}`);
      dispatch({ type: SAVE_HERO_IMAGE });
    }
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const deleteLifeEvent =
  (rowDetails, personLifeEvents, treeId, treePersonId, person) => async (dispatch) => {
    try {
      let payload, result;
      let spousalCheck = isSpousalEvent(rowDetails.type);

      dispatch({ type: DELETING_EVENT });

      if (spousalCheck) {
        payload = getSpousalPayloadForDeleteEvent(rowDetails, personLifeEvents, treeId);
        result = await apiRequest(DELETE, `Persons/deletespousalassertion/`, payload);
      } else {
        payload = getPayloadForDeleteEvent(rowDetails, personLifeEvents, treeId, treePersonId);
        result = await apiRequest(DELETE, `Persons/deletepersonassertion/`, payload);
      }
      if (result && result.status === 200) {
        setTimeout(() => {
          dispatch(addMessage(`${payload.eventType} event has been deleted.`, "success"));
        }, 2000);
      }
      if (
        rowDetails &&
        (rowDetails.type === "Marriage" ||
          rowDetails.type === "Birth" ||
          rowDetails.type === "Death")
      ) {
        const lifeEvents = await apiRequest(GET, `persons/${treePersonId}/alllifeevents?${treeId}`);
        dispatch({ type: GET_LIFEEVENTS, payload: lifeEvents.data });
        const eventsInfo = await apiRequest(GET, `persons/${treeId}/${treePersonId}/lifeevents`);
        dispatch({ type: GET_EVENTS, payload: eventsInfo.data.Events });
        const personalInfo = await apiRequest(GET, `persons/${treePersonId}/info`);
        const personalInfo_res = { data: { personalInfo: personalInfo.data, relation } };
        if (personalInfo.data)
          dispatch({ type: GET_PERSONAL_INFO, payload: personalInfo_res.data });
        let personData = {
          ...person,
          personalInfo: personalInfo_res.data.personalInfo,
          events: eventsInfo.data.Events,
          lifeEvents: lifeEvents.data
        };
        let newDataPayload = getNewDataPayloadForDeleteEvent(payload, treePersonId);
        const optimisticDetails = getOptimisticDetails(
          newDataPayload,
          "deleteLifeEvent",
          personData
        );
        if (optimisticDetails) dispatch({ type: WHOLE_REFETCHED, payload: optimisticDetails });
      } else if (rowDetails.type === "Divorce") {
        const divorceLifeEvents = await apiRequest(
          GET,
          `persons/${treePersonId}/alllifeevents?${treeId}`
        );
        dispatch({ type: GET_LIFEEVENTS, payload: divorceLifeEvents.data });
        const divorceEventsInfo = await apiRequest(
          GET,
          `persons/${treeId}/${treePersonId}/lifeevents`
        );
        dispatch({ type: GET_EVENTS, payload: divorceEventsInfo.data.Events });
      } else {
        const allLifeEvents = await apiRequest(
          GET,
          `persons/${treePersonId}/alllifeevents?${treeId}`
        );
        dispatch({ type: GET_LIFEEVENTS, payload: allLifeEvents.data });
      }

      dispatch({ type: DELETED_EVENT });
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

function optimisticUpdateTreeProgress(person, currentTreeProgress, dispatch) {
  const updatedAttributes = {
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    open: false
  };
  let deletedNode;
  const updatedSortedCheckList = currentTreeProgress.sortedCheckListPayload.map((p) => {
    let newItem = p;
    if (p.id === person.id) {
      deletedNode = p;
      newItem = { ...p, ...updatedAttributes };
    }
    if (deletedNode?.path.length > 1 && p.path.startsWith(deletedNode?.path)) {
      newItem = { ...p, ...updatedAttributes };
      if (p?.path.length === 1) {
        const { node, ...rest } = newItem;
        newItem = rest;
      }
    }
    if (deletedNode?.path.length === 1 && p.path.startsWith(deletedNode?.path)) {
      newItem = { ...p, ...updatedAttributes };
      if (p?.path.length > 1) {
        const { node, ...rest } = newItem;
        newItem = rest;
      }
    }
    return newItem;
  });
  const preferenceRelation = getPreferenceRelation(updatedSortedCheckList);
  const preferenceDetail = getPreferenceDetail(updatedSortedCheckList);
  const preferenceDetailFocus = getPreferenceDetailFocus(preferenceDetail);

  dispatch({
    type: TREE_PROGRESS_PAYLOAD,
    payload: {
      treeData: computeTreeData(updatedSortedCheckList),
      preferenceRelation,
      preferenceDetail,
      preferenceDetailFocus,
      sortedCheckListPayload: updatedSortedCheckList
    }
  });
}

export const deletePerson = (person, onSuccess) => async (dispatch, getState) => {
  try {
    const currentTreeProgress = getState().family.treeProgressPayload;

    dispatch({ type: DELETE_PERSON_LOADING });
    await apiRequest(DELETE, `Persons/deleteperson/${person.id}`);
    if (person.isRowEvent) {
      setTimeout(() => {
        dispatch({ type: REFETCH_FAMILY_INFO });
      }, 1000);
    } else {
      setTimeout(() => {
        dispatch({ type: DELETE_PERSON_LOADED, payload: person });
      }, 1000);
    }
    if (currentTreeProgress) {
      optimisticUpdateTreeProgress(person, currentTreeProgress, dispatch);
    }
    setTimeout(() => {
      dispatch(addMessage(` ${person.firstName} ${person.lastName} has been deleted.`, "success"));
    }, 2000);
    onSuccess();
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const refetchFamilyInfo = (treeId, primaryPersonId) => async (dispatch) => {
  try {
    const spousesAndChildren = apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/spouseswithchildren?addUnknownParents=1`
    );
    const parentsAndSiblings = apiRequest(
      GET,
      `persons/${treeId}/${primaryPersonId}/parentswithsiblings?addUnknownParents=1`
    );
    const eventsInfo = await apiRequest(GET, `persons/${treeId}/${primaryPersonId}/lifeevents`);
    const [spousesAndChildren_res, parentsAndSiblings_res, eventsInfo_res] = await Promise.all([
      spousesAndChildren,
      parentsAndSiblings,
      eventsInfo
    ]);
    if (spousesAndChildren_res.data)
      dispatch({ type: GET_SPOUSES, payload: spousesAndChildren_res.data.Spouses });
    if (parentsAndSiblings_res.data)
      dispatch({ type: GET_PARENTS, payload: parentsAndSiblings_res.data });
    if (eventsInfo_res.data) dispatch({ type: GET_EVENTS, payload: eventsInfo_res.data.Events });
    dispatch({ type: REFETCH_FAMILY_INFO_COMPLETE });
  } catch (err) {
    dispatch({
      type: PERSON_ERROR,
      payload: { msg: err }
    });
  }
};

export const getMemberDetails = (userId) => async (dispatch) => {
  let url = `users/${userId}/memberDetail`;
  try {
    dispatch(actionCreator(CONSTANTS.GETMEMBERDETAILS.REQUEST));
    const memberData = await apiRequest(GET, url);
    if (memberData.data) {
      dispatch(actionCreator(CONSTANTS.GETMEMBERDETAILS.SUCCESS, memberData.data));
    }
  } catch (err) {
    dispatch(actionCreator(CONSTANTS.GETMEMBERDETAILS.FAILURE));
  }
};

export const getPersonInformation = async (userId) => {
  try {
    const response = await apiRequest("GET", personInformation(userId));

    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const acceptPotentialParentViaPersonPage =
  (childData, parentData, treeId) => async (dispatch, getState) => {
    try {
      const parentsAndSiblingsPayload = getState().person.parentsAndSiblings;
      const payload = addPotentialPersonPayload(childData, parentData, treeId);
      await apiRequest(POST, `Persons/potentialparent`, payload);
      const localPayload = updateFamilyOnPotentialParentAcceptance(
        childData,
        parentData,
        parentsAndSiblingsPayload,
        payload
      );
      dispatch({ type: UPDATE_PARENTS_AND_SIBLINGS, payload: localPayload });
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

export const rejectPotentialParentViaPersonPage =
  (rejectPayload, potentialParent) => async (dispatch, getState) => {
    try {
      const parentsAndSiblingsPayload = getState().person.parentsAndSiblings;
      await apiRequest(POST, `Persons/rejectnewPersonhint`, rejectPayload);
      const localPayload = updateFamilyOnPotentialParentReject(
        potentialParent,
        parentsAndSiblingsPayload
      );
      dispatch({ type: UPDATE_PARENTS_AND_SIBLINGS, payload: localPayload });
    } catch (err) {
      dispatch({
        type: PERSON_ERROR,
        payload: { msg: err }
      });
    }
  };

export const updateNotFoundError = () => async (dispatch) => {
  dispatch({
    type: NOT_FOUND_ERROR,
    payload: false
  });
};
