/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  StoryBookCreateStoryBookData,
  StoryBookCreateStoryBookPayload,
  StoryBookData,
  StoryBookDeleteDraftData,
  StoryBookOrderAdditionalData,
  StoryBookOrderAdditionalPayload,
  StoryBookSavestorybookcoverdetailsData,
  StoryBookSavestorybookcoverdetailsPayload,
  StoryBookSelectedStoriesForBookData,
  StoryBookSetStoriesOrderData,
  StoryBookSetStoriesOrderPayload,
  StoryBookStoryPreviewData,
  StoryBookUpdateStoriesData,
  StoryBookUpdateStoriesPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class StoryBook extends HttpClient {
  /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookSetStoriesOrder
   * @request PUT:/api/StoryBook/{storyBookId}/setStoriesOrder
   * @secure
   */
  storyBookSetStoriesOrder = (
    storyBookId: string,
    data: StoryBookSetStoriesOrderPayload,
    params: RequestParams = {}
  ) =>
    this.request<StoryBookSetStoriesOrderData, any>({
      path: `/api/StoryBook/${storyBookId}/setStoriesOrder`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookCreateStoryBook
   * @request POST:/api/StoryBook/createStoryBook
   * @secure
   */
  storyBookCreateStoryBook = (data: StoryBookCreateStoryBookPayload, params: RequestParams = {}) =>
    this.request<StoryBookCreateStoryBookData, any>({
      path: `/api/StoryBook/createStoryBook`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookOrderAdditional
   * @request POST:/api/StoryBook/order-additional
   * @secure
   */
  storyBookOrderAdditional = (data: StoryBookOrderAdditionalPayload, params: RequestParams = {}) =>
    this.request<StoryBookOrderAdditionalData, any>({
      path: `/api/StoryBook/order-additional`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookUpdateStories
   * @request PUT:/api/StoryBook/{storyBookId}/updateStories
   * @secure
   */
  storyBookUpdateStories = (
    storyBookId: string,
    data: StoryBookUpdateStoriesPayload,
    params: RequestParams = {}
  ) =>
    this.request<StoryBookUpdateStoriesData, any>({
      path: `/api/StoryBook/${storyBookId}/updateStories`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookSavestorybookcoverdetails
   * @request PUT:/api/StoryBook/savestorybookcoverdetails
   * @secure
   */
  storyBookSavestorybookcoverdetails = (
    data: StoryBookSavestorybookcoverdetailsPayload,
    params: RequestParams = {}
  ) =>
    this.request<StoryBookSavestorybookcoverdetailsData, any>({
      path: `/api/StoryBook/savestorybookcoverdetails`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.FormData,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookDeleteDraft
   * @request DELETE:/api/StoryBook/{storyBookId}/delete-draft
   * @secure
   */
  storyBookDeleteDraft = (storyBookId: string, params: RequestParams = {}) =>
    this.request<StoryBookDeleteDraftData, any>({
      path: `/api/StoryBook/${storyBookId}/delete-draft`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBook
   * @request GET:/api/StoryBook/{storybookid}
   * @secure
   */
  storyBook = (storybookid: string, params: RequestParams = {}) =>
    this.request<StoryBookData, any>({
      path: `/api/StoryBook/${storybookid}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookSelectedStoriesForBook
   * @request GET:/api/StoryBook/{storybookid}/selectedStoriesForBook
   * @secure
   */
  storyBookSelectedStoriesForBook = (storybookid: string, params: RequestParams = {}) =>
    this.request<StoryBookSelectedStoriesForBookData, any>({
      path: `/api/StoryBook/${storybookid}/selectedStoriesForBook`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags StoryBook
   * @name StoryBookStoryPreview
   * @request GET:/api/StoryBook/story-preview/{storyId}
   * @secure
   */
  storyBookStoryPreview = (storyId: string, params: RequestParams = {}) =>
    this.request<StoryBookStoryPreviewData, any>({
      path: `/api/StoryBook/story-preview/${storyId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
