export function sendRefersionCheckoutEvent(cartID) {
  const rfsn = {
    cart: cartID,
    id: localStorage.getItem("rfsn_v4_id"),
    url: window.location.href,
    aid: localStorage.getItem("rfsn_v4_aid"),
    cs: localStorage.getItem("rfsn_v4_cs"),
  };
  window.r && window.r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
  for( let k in window.localStorage) {
    if( k.includes("rfsn")) delete window.localStorage[k];
    else continue;
 }
}
