import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "redux/utils/useTypeSelector";
import { tr } from "../../components/utils";
import { removeMessage, SnackbarMessage, SnackbarType } from "../../redux/actions/toastr";

const severityTypeMap: Record<SnackbarType, AlertProps["severity"]> = {
  error: "error",
  notification: "info",
  success: "success",
  warning: undefined,
  info: undefined
};

function SlideTransition(props: Omit<SlideProps, "direction">): JSX.Element {
  return <Slide {...props} direction="up" />;
}
function getActionText(type: SnackbarType) {
  let text = "Undo";
  if (type === "error") {
    text = "Try Again";
  }
  return text;
}
function getActionClass(type: SnackbarType | "info") {
  let _class = "text-green-1";
  if (type === "error") {
    _class = "text-maroon-1";
  } else if (type === "info") {
    _class = "text-skyblue-1";
  } else if (type === "notification") {
    _class = "text-skyblue-3";
  }
  return _class;
}
export default function SnackbarComponent({ timing = 6000 }: { timing?: number }) {
  const { t } = useTranslation();

  const transition = SlideTransition;
  const dispatch = useDispatch();
  const { message: messageList } = useTypedSelector((state) => {
    return state.toastr;
  });
  const handleClose = (message: SnackbarMessage, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeMessage(message.key));
  };

  const handleExited = (message: SnackbarMessage) => {
    dispatch(removeMessage(message.key));
  };
  const getAction = (message: SnackbarMessage) => {
    const classesAction = getActionClass(message.type);
    return (
      <span
        className={`${classesAction} ml-6 cursor-pointer hover:underline`}
        onClick={() => {
          message.cta?.action?.();
          handleExited(message);
        }}
      >
        {message.cta?.label ? message.cta.label : getActionText(message.type)}
      </span>
    );
  };

  const displayContent = (message: SnackbarMessage) => {
    return message.content?.split("<br>").map((msg, index) => (
      <>
        <span key={index}> {tr(t, msg)}</span>
        {message.cta && getAction(message)}
      </>
    ));
  };
  const displayMessage = (message: SnackbarMessage) => {
    let html = null;
    if (message?.url) {
      html = (
        <p className={`max-w-130`}>
          {displayContent(message)}
          <a href={message.url} className="ml-4">
            View
          </a>
        </p>
      );
    } else {
      html = <p className={`max-w-130`}>{displayContent(message)}</p>;
    }
    return html;
  };
  return (
    <div className="multi-snkbr-toaster">
      {messageList.map((message) => (
        <Snackbar
          key={message.key}
          sx={{
            "&:nth-child(2)": {
              bottom: "80px"
            },
            "&:nth-child(3)": {
              bottom: "136px"
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          autoHideDuration={timing}
          onClose={(_event, reason) => handleClose(message, reason)}
          TransitionComponent={transition}
          open={message !== null}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{
              width: "100%"
            }}
            onClose={message.canClose ? () => handleExited(message) : undefined}
            severity={severityTypeMap[message.type]}
            icon={message.showIcon}
          >
            {displayMessage(message)}
          </MuiAlert>
        </Snackbar>
      ))}
    </div>
  );
}
