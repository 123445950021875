import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { REQUEST_HINT_GENERATION } from "redux/constants/actionTypes";
import { HINT_GENERATION } from "redux/constants/apiUrl";
import { actionCreator } from "redux/utils";
import { trimString } from "shared-logics";
import { getNewTree, setCardTooltip, setFocusSiblingAdded } from "../../services";
import { getOwner } from "../../services/index";
import { BLANKID, FEMALE, MALE, refetchLogic, upDatePotentialFamily, findNodeItem, updateParentsInStoryTab } from "../../utils";
import {
  ADD_CHILD,
  ADD_PARENT,
  ADD_PARENT_VIA_PLACEHOLDER,
  ADD_PERSON_THUMBNAIL,
  ADD_PET_THUMBNAIL,
  ADD_RELATED_EVENT,
  ADD_SIBLING,
  ADD_SPOUSE,
  APPEND_RELATIONSHIP,
  AUTOCOMPLETE_BIRTH_PAGINATION_TEST,
  AUTOCOMPLETE_BIRTH_TEST,
  AUTOCOMPLETE_DEATH_PAGINATION_TEST,
  AUTOCOMPLETE_DEATH_TEST,
  AUTOCOMPLETE_ERROR,
  AUTOCOMPLETE_PAGINATION_ERROR,
  AUTOCOMPLETE_PAGINATION_TEST,
  AUTOCOMPLETE_REQUEST,
  AUTOCOMPLETE_TEST,
  CANCEL_MODAL,
  CLEAR_BIRTH_OPTIONS,
  CLEAR_DEATH_OPTIONS,
  CLEAR_EVENT_INFO,
  CLEAR_FAMILY,
  CLEAR_IMAGE,
  CLEAR_IS_PRIVATE_TREE,
  CLEAR_RESIDENCE_OPTIONS,
  CLOSE_ADD_GRANDPARENT_MODAL,
  CLOSE_TREE_PROGRESS_MODAL,
  COLLECTIONID_HIGHEST_HINT,
  DELETE,
  DELETE_PET_PROFILE_IMAGE,
  DIRECT_CHILDREN,
  FAMILY_ERROR,
  FAMILY_LOADING,
  FETCHING_IMAGE,
  FETCH_PARENTS,
  FETCH_SPOUSES,
  GET,
  GET_EDIT_PERSON,
  GET_FAMILY,
  GET_HERO_IMAGE,
  GET_PROFILE_IMAGE,
  IMAGE_LOADING,
  IMAGE_UNLOADING,
  IMPORT_STATUS_ERROR,
  IS_PRIVATE_TREE,
  NEW_NODE_HIGHTLIGHT,
  PERSON_LOADING,
  POST,
  PUT,
  REFETCHED,
  REFETCH_FAMILY_INFO_COMPLETE,
  REFETCH_PAGE_AFTER_UPLOAD,
  REFETCH_TREE_PROGRESS,
  RENDER_NEW_TREE,
  RENDER_TREE,
  SAVED_MODAL,
  SAVE_CHILD,
  SAVE_ERROR,
  SAVE_PARENT,
  SAVE_PARENT_VIA_PLACEHOLDER,
  SAVE_PROFILE_IMAGE,
  SAVE_SIBLING,
  SAVE_SPOUSE,
  SAVING_MODAL,
  SELECTED_PARENT_SIDE,
  SET_EVENT_MODAL_DATA,
  SET_HINTS_PAYLOAD,
  SET_IS_GEDCOM_UPLOADED,
  SET_MATCHES,
  SET_MODAL_DATA,
  SET_NEW_FAMILY_PAYLOAD,
  SHOW_ADD_GRANDPARENT_MODAL,
  SHOW_IMAGE,
  SHOW_TREE_CELEBRATORY_MODAL,
  TREE_PERSON_SEARCH_OPTIONS,
  TREE_PROGRESS_PAYLOAD,
  UPDATE_FAMILY,
  UPDATE_FIRST_HINT_SEEN,
  UPDATE_HINT_COUNT_ITEM,
  UPDATE_SELECTED_PARENT_SIDE,
  GETRIGHTPANELDETAILS_SUCCESS,
  NOT_FOUND_ERROR,
} from "../constants";
import {
  addFilialFunction,
  editImageUploadPayload,
  editPersonPayload,
  getChildPayload,
  getParentAndSpousesPayload,
  getParentPayload,
  getRelatedEventPayload,
  getSiblingDropdown,
  getSiblingPayload,
  getSpouseAndChildrenPayload,
  getSpouseOptions,
  getSpousePayload,
  getValuesForCheckBoxes,
  imageUploadPayload,
  saveIndividualPayload,
  saveParentPayload,
  saveSiblingPayload,
  saveSpousePayload,
  addPotentialPersonPayload,
} from "../helpers";
import { apiRequest, isCancel } from "../requests";
import { addMessage } from "./toastr";

export const getFamily = (treeId, primaryPersonId, level) => async (dispatch) => {
  try {
    setFocusSiblingAdded(false);
    dispatch({ type: FAMILY_LOADING });

    const res = await apiRequest(GET, `persons/pedigree/${treeId}/${primaryPersonId}/${level}`);
    if (getNewTree() === "true") setCardTooltip(true);
    else setCardTooltip(false);
    if (res?.data) {
      setTimeout(async () => {
        await dispatch({
          type: GET_FAMILY,
          payload: res.data
        });
      }, 100);
    }
  } catch (err) {
    if(err.response.status === 404){
      dispatch({
        type: NOT_FOUND_ERROR,
        payload : true
      });
    }else{
      dispatch({
        type: FAMILY_ERROR,
        payload: { msg: err }
      });
    }
  }
};

export const refetchComplete = () => (dispatch) => {
  try {
    dispatch({ type: REFETCH_FAMILY_INFO_COMPLETE });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const isTreeAccessible = (treeId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_FAMILY });

    const res = await apiRequest(GET, `Persons/istreeaccessible?treeId=${treeId}`);

    dispatch({
      type: IS_PRIVATE_TREE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearIsTreeAccessible = () => (dispatch) => {
  dispatch({ type: CLEAR_IS_PRIVATE_TREE });
};

export const refetchFamilyTreeAndList = (treeId, primaryPersonId, level) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `persons/pedigree/${treeId}/${primaryPersonId}/${level}`);
    dispatch({
      type: GET_FAMILY,
      payload: res.data
    });
    const list_res = await apiRequest(GET, `Trees/${treeId}/listPeople`);
    dispatch({
      type: TREE_PERSON_SEARCH_OPTIONS,
      payload: list_res.data
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const refetchFamily = (treeId, primaryPersonId, level) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `persons/pedigree/${treeId}/${primaryPersonId}/${level}`);
    dispatch({
      type: GET_FAMILY,
      payload: res.data
    });
    dispatch({ type: REFETCHED });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const refetchFamilyOnly = (treeId, primaryPersonId, level) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `persons/pedigree/${treeId}/${primaryPersonId}/${level}`);
    dispatch({
      type: GET_FAMILY,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearFamily = () => (dispatch) => {
  dispatch({ type: CLEAR_FAMILY });
};

export const renderTree = () => (dispatch) => {
  dispatch({ type: RENDER_TREE });
};

export const getNextGenFamily = (treeId, primaryPersonId, level) => async (dispatch) => {
  let path = level?.split("/");
  // Appending unicode for "/" as backend read the "/" as different parameter
  path = path.join("%2F");
  try {
    const res = await apiRequest(GET, `Persons/expandtree/${treeId}/${primaryPersonId}/${path}`);
    return res.data;
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const getEditPerson = (payload) => (dispatch) => {
  try {
    dispatch({
      type: GET_EDIT_PERSON,
      payload
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

function sumAllChecks(person) {
  const attributes = ["name", "birthDate", "birthLocation", "residence"];

  return attributes.reduce((acc, attribute) => {
    if (person[attribute]) {
      acc++;
    }
    return acc;
  }, 0);
}

export function computeTreeData(sortedCheckList) {
  const totalChecks = sortedCheckList.reduce((acc, person) => {
    acc += person.check;
    return acc;
  }, 0);
  const totalDataPoints = sortedCheckList.length * 4;
  const percentageComplete = Math.ceil((totalChecks / totalDataPoints) * 100);
  return { percentageComplete, totalDataPoints: totalChecks };
}

function checkIfPersonHasResidence(person) {
  const personResidences = person.upDatedResidenceEvents ?? [];
  return personResidences.filter((event) => event.location || event.locationId).length > 0;
}

function updatePersonInSortedCheckList(updatedPerson, sortedCheckList) {
  return sortedCheckList.map((p) => {
    if (p.id === updatedPerson.id) {
      return updatedPerson;
    }
    return p;
  });
}

async function checkForTreeProgressCompletion({ treeId, percentageComplete, dispatch }) {
  if (percentageComplete === 100) {
    await apiRequest(POST, `Trees/${treeId}/buildguidancecompleted`);
    dispatch({ type: SHOW_TREE_CELEBRATORY_MODAL });
  }
}

function optimisticUpdateTreeProgressForEditPerson({
  editPerson,
  currentTreeProgress,
  treeId,
  dispatch
}) {
  const sortedCheckList = currentTreeProgress.sortedCheckListPayload;
  const personToUpdate = sortedCheckList.find((p) => p.id === editPerson.id);
  if (!personToUpdate) {
    return;
  }

  const updatedPerson = {
    ...personToUpdate,
    quickEditNode: getOptimisticQuickEditNode(editPerson),
    name: Boolean(editPerson.firstName) || Boolean(editPerson.lastName),
    birthDate: Boolean(editPerson.birth),
    birthLocation: Boolean(editPerson.birthPlace),
    residence: checkIfPersonHasResidence(editPerson)
  };
  updatedPerson.check = sumAllChecks(updatedPerson);
  const updatedSortedCheckList = updatePersonInSortedCheckList(updatedPerson, sortedCheckList);
  const computedTreeData = computeTreeData(updatedSortedCheckList);
  let sortedCheckListPayload = updatedSortedCheckList.sort((a, b) => {
    return a.orderId - b.orderId;
  });
  let openedAccordian = false;
  const preferenceRelation = getPreferenceRelation(sortedCheckListPayload);
  const preferenceDetail = getPreferenceDetail(sortedCheckListPayload);
  const preferenceDetailFocus = getPreferenceDetailFocus(preferenceDetail);
  sortedCheckListPayload = sortedCheckListPayload.map((item) => {
    if (item.check === 4 && item.open) {
      return { ...item, open: false };
    }
    if (item.check < 4 && openedAccordian === false) {
      openedAccordian = true;
      return { ...item, open: true };
    }
    return item;
  });

  checkForTreeProgressCompletion({
    treeId,
    percentageComplete: computedTreeData.percentageComplete,
    dispatch
  });

  dispatch({
    type: TREE_PROGRESS_PAYLOAD,
    payload: {
      treeData: computedTreeData,
      preferenceRelation,
      preferenceDetail,
      preferenceDetailFocus,
      sortedCheckListPayload
    }
  });
}

export const saveEditPerson =
  (family, person, editPerson, treeId, onEditPerson) => async (dispatch, getState) => {
    try {
      const currentTreeProgress = getState().family.treeProgressPayload;
      const personData = editPersonPayload(person, editPerson, treeId);
      if (trimString(editPerson.firstName) === "" && trimString(editPerson.lastName) === "") {
        dispatch(addMessage("First or last name is required.", "error"));
      } else {
        dispatch({ type: SAVING_MODAL });
        dispatch({ type: SET_NEW_FAMILY_PAYLOAD, payload: family });
        if (Object.keys(personData).length > 2) {
          await apiRequest(PUT, `Persons/${person.id}`, personData);
        }
        onEditPerson(editPerson);
        if (currentTreeProgress) {
          optimisticUpdateTreeProgressForEditPerson({
            editPerson,
            currentTreeProgress,
            treeId,
            dispatch
          });
        }
        dispatch({ type: SAVED_MODAL });
        let url = `/family/person-page/details/${treeId}/${editPerson.id}`;
        setTimeout(() => {
          dispatch(
            addMessage(
              `${editPerson.firstName} ${editPerson.lastName} has been updated.`,
              "success",
              { url }
            )
          );
        }, 1000);
      }
    } catch (err) {
      dispatch({
        type: FAMILY_ERROR,
        payload: { msg: err }
      });
      dispatch(addMessage("Sorry, person couldn't be updated. Please try again.", "error"));
    }
  };

export const addParentsViaPlaceHolders = () => (dispatch) => {
  dispatch({ type: ADD_PARENT_VIA_PLACEHOLDER });
};

const getTitleForGrandparent = (path) => {
  const paths = {
    0: "btnText.AddYourFather",
    1: "btnText.AddYourMother",
    "0/0": "Add Father",
    "0/1": "Add Mother",
    "1/0": "Add Father",
    "1/1": "Add Mother"
  };
  return paths[path];
};

const getGenderForGrandParents = (path) => {
  const paths = {
    0: "btnText.AddYourFather",
    1: "btnText.AddYourMother",
    "0/0": "Male",
    "0/1": "Female",
    "1/0": "Male",
    "1/1": "Female"
  };
  return paths[path];
};

const getSelectedNodeForGrandparents = (p, child) => {
  return {
    id: "",
    parentId: child.id,
    cFilialId: "",
    type: "medium-next-gen",
    path: p.path,
    title: getTitleForGrandparent(p.path),
    firstName: "",
    firstNameWithInitials: "",
    lastName: "",
    isLiving: true,
    gender: getGenderForGrandParents(p.path),
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    imgsrc: "",
    relatedParentIds: null,
    residenceEvents: []
  };
};

function optimisticUpdateTreeProgressForAddPerson({
  parentData,
  currentTreeProgress,
  newTreeProgressParentPath,
  treeId,
  dispatch
}) {
  const updatedPerson = {
    quickEditNode: getQuickEditAddNode(parentData),
    name: Boolean(parentData.firstName) || Boolean(parentData.lastName),
    birthDate: Boolean(parentData.attributes?.birth?.rawDate),
    birthLocation: Boolean(parentData.attributes?.birthLocation),
    residence: checkIfPersonHasResidence(parentData)
  };
  updatedPerson.check = sumAllChecks(updatedPerson);
  const updatedSortedCheckList = currentTreeProgress.sortedCheckListPayload.map((p) => {
    if (p.path === newTreeProgressParentPath) {
      return { ...p, ...updatedPerson, id: parentData.id };
    }
    if (p.path.startsWith(newTreeProgressParentPath)) {
      return { ...p, node: getSelectedNodeForGrandparents(p, parentData) };
    }
    return p;
  });
  const computedTreeData = computeTreeData(updatedSortedCheckList);
  let sortedCheckListPayload = updatedSortedCheckList.sort((a, b) => {
    return a.orderId - b.orderId;
  });
  let openedAccordian = false;
  const preferenceRelation = getPreferenceRelation(sortedCheckListPayload);
  const preferenceDetail = getPreferenceDetail(sortedCheckListPayload);
  const preferenceDetailFocus = getPreferenceDetailFocus(preferenceDetail);
  sortedCheckListPayload = sortedCheckListPayload.map((item) => {
    if (item.check === 4 && item.open) {
      return { ...item, open: false };
    }
    if (item.check < 4 && openedAccordian === false) {
      openedAccordian = true;
      return { ...item, open: true };
    }
    return item;
  });

  checkForTreeProgressCompletion({
    treeId,
    percentageComplete: computedTreeData.percentageComplete,
    dispatch
  });

  dispatch({
    type: TREE_PROGRESS_PAYLOAD,
    payload: {
      treeData: computedTreeData,
      preferenceRelation,
      preferenceDetail,
      preferenceDetailFocus,
      sortedCheckListPayload
    }
  });
}

export const saveParentsViaPlaceHolders =
  (childData, parentData, treeId, newTreeProgressParentPath, onSavePerson) =>
    async (dispatch, getState) => {
      try {
        const currentTreeProgress = getState().family.treeProgressPayload;
        const payload = addFilialFunction(childData, parentData, treeId);
        if (trimString(parentData.firstName) === "" && trimString(parentData.lastName) === "") {
          dispatch(addMessage("First or last name is required.", "error"));
        } else {
          dispatch({ type: SAVING_MODAL });
          dispatch({ type: PERSON_LOADING });
          await apiRequest(POST, `Persons/parent`, payload);
          onSavePerson(parentData.id);
          dispatch({ type: SAVE_PARENT_VIA_PLACEHOLDER });
          if (currentTreeProgress) {
            optimisticUpdateTreeProgressForAddPerson({
              parentData,
              currentTreeProgress,
              newTreeProgressParentPath,
              treeId,
              dispatch
            });
          }

          dispatch({ type: SAVED_MODAL });
        }
      } catch (err) {
        dispatch({
          type: SAVE_ERROR,
          payload: { msg: err }
        });
        dispatch(addMessage("Sorry, person couldn't be saved. Please try again.", "error"));
      }
    };

const getModalTitle = (nodePath) => {
  if (nodePath === "0") return "Your dad has been added!";
  if (nodePath === "1") return "Your mom has been added!";
  if (nodePath === "0/0" || nodePath === "1/0") return "Your grandpa has been added!";
  if (nodePath === "0/1" || nodePath === "1/1") return "Your grandma has been added!";
};

const getModalSubTitle = (nodePath, parentSide) => {
  if (nodePath === "0")
    return "Your father has successfully been added to your family tree. Next up: add your grandpa.";
  if (nodePath === "1")
    return "Your mother has successfully been added to your family tree. Next up: add your grandpa.";
  if (nodePath === "0/0" || nodePath === "1/0")
    return "Your grandpa has successfully been added to your family tree. Next up: add your grandma.";
  if (nodePath === "0/1" && parentSide === "Dad")
    return "Your dad’s mom has successfully been added to your family tree.";
  if (nodePath === "1/1" && parentSide === "Mom")
    return "Your mom’s mom has successfully been added to your family tree.";
};

const getModalBtnText = (nodePath) => {
  if (nodePath === "0" || nodePath === "1") return "addGrandpa";
  if (nodePath === "0/0" || nodePath === "1/0") return "addGrandma";
  if (nodePath === "0/1" || nodePath === "1/1") return "person.btn.viewtree";
};

const getmodalPersonAdded = (nodePath) => {
  if (nodePath === "0" || nodePath === "1") return "Father";
  if (nodePath === "0/0" || nodePath === "1/0") return "GrandFather";
  if (nodePath === "0/1" || nodePath === "1/1") return "GrandMother";
};

const checkViewTree = (nodePath) => {
  return nodePath === "0/1" || nodePath === "1/1";
};
const getNewNodeData = (parentData, parentSide) => {
  switch (parentSide) {
    case "Dad":
      return {
        node: {
          id: "",
          parentId: parentData?.id,
          cFilialId: "",
          type: "medium-next-gen",
          path: parentData.nodePath === "0" ? "0/0" : "0/1",
          title: parentData.nodePath === "0" ? "Add Grandpa" : "Add Grandma",
          firstName: "",
          firstNameWithInitials: "",
          lastName: parentData.nodePath === "0" ? trimString(parentData.lastName) : "",
          isLiving: true,
          gender: parentData.nodePath === "0" ? MALE : FEMALE,
          birth: "",
          birthPlace: "",
          death: "",
          deathPlace: "",
          imgsrc: "",
          relatedParentIds: null,
          residenceEvents: []
        },
        modalPersonAdded: getmodalPersonAdded(parentData.nodePath),
        modalTitle: getModalTitle(parentData.nodePath),
        modalSubTitle: getModalSubTitle(parentData.nodePath, parentSide),
        modalBtnText: getModalBtnText(parentData.nodePath),
        viewTree: checkViewTree(parentData.nodePath)
      };
    case "Mom":
      return {
        node: {
          id: "",
          parentId: parentData?.id,
          cFilialId: "",
          type: "medium-next-gen",
          path: parentData.nodePath === "1" ? "1/0" : "1/1",
          title: parentData.nodePath === "1" ? "Add Grandpa" : "Add Grandma",
          firstName: "",
          firstNameWithInitials: "",
          lastName: parentData.nodePath === "1" ? trimString(parentData?.lastName) : "",
          isLiving: true,
          gender: parentData.nodePath === "1" ? MALE : FEMALE,
          birth: "",
          birthPlace: "",
          death: "",
          deathPlace: "",
          imgsrc: "",
          relatedParentIds: null,
          residenceEvents: []
        },
        modalPersonAdded: getmodalPersonAdded(parentData.nodePath),
        modalTitle: getModalTitle(parentData.nodePath),
        modalSubTitle: getModalSubTitle(parentData.nodePath, parentSide),
        modalBtnText: getModalBtnText(parentData.nodePath),
        viewTree: checkViewTree(parentData.nodePath)
      };
    default:
      return;
  }
};

export const showSnackBarModal = (treeId, parentData, selectedParentSide) => (dispatch) => {
  const newNodeData = getNewNodeData(parentData, selectedParentSide);
  dispatch({
    type: NEW_NODE_HIGHTLIGHT,
    payload: null
  });
  let url = `/family/person-page/details/${treeId}/${parentData.id}`;
  if (selectedParentSide) {
    setTimeout(() => {
      dispatch({
        type: SHOW_ADD_GRANDPARENT_MODAL,
        payload: newNodeData
      });
      dispatch({
        type: RENDER_NEW_TREE,
        payload: null
      });
    }, 750);
  } else {
    setTimeout(() => {
      dispatch(
        addMessage(`${parentData.firstName} ${parentData.lastName} has been saved.`, "success", {
          url
        })
      );
    }, 1000);
  }
};

export const getTreePersonOptions = (treeId) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `Trees/${treeId}/listPeople`);
    dispatch({
      type: TREE_PERSON_SEARCH_OPTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: IMPORT_STATUS_ERROR,
      payload: { msg: err }
    });
  }
};

export const getAutoCompleteTest =
  (searchString, source, requestId, page = 1) =>
    async (dispatch) => {
      try {
        if (requestId && searchString) {
          const res = await apiRequest(
            GET,
            `placeauthority/typeahead/search/${requestId}/page/${page}/${searchString}`,
            null,
            null,
            source
          );
          if ((res.data && page !== 1) || page === 1) {
            const actionType = page === 1 ? AUTOCOMPLETE_TEST : AUTOCOMPLETE_PAGINATION_TEST;
            dispatch({
              type: actionType,
              payload: res.data
            });
          }
        }
      } catch (err) {
        if (!isCancel(err)) {
          dispatch({
            type: page === 1 ? AUTOCOMPLETE_ERROR : AUTOCOMPLETE_PAGINATION_ERROR,
            payload: { msg: err }
          });
        }
      }
    };

export const getBirthAutoCompleteTest =
  (searchString, source, requestId, page = 1) =>
    async (dispatch) => {
      try {
        if (requestId && searchString) {
          const res = await apiRequest(
            GET,
            `placeauthority/typeahead/search/${requestId}/page/${page}/${searchString}`,
            null,
            null,
            source
          );
          if ((res.data && page !== 1) || page === 1) {
            const actionType =
              page === 1 ? AUTOCOMPLETE_BIRTH_TEST : AUTOCOMPLETE_BIRTH_PAGINATION_TEST;
            dispatch({
              type: actionType,
              payload: res.data
            });
          }
        }
      } catch (err) {
        if (!isCancel(err)) {
          dispatch({
            type: page === 1 ? AUTOCOMPLETE_ERROR : AUTOCOMPLETE_PAGINATION_ERROR,
            payload: { msg: err }
          });
        }
      }
    };

export const getDeathAutoCompleteTest =
  (searchString, source, requestId, page = 1) =>
    async (dispatch) => {
      try {
        if (requestId && searchString) {
          const res = await apiRequest(
            GET,
            `placeauthority/typeahead/search/${requestId}/page/${page}/${searchString}`,
            null,
            null,
            source
          );
          if ((res.data && page !== 1) || page === 1) {
            const actionType =
              page === 1 ? AUTOCOMPLETE_DEATH_TEST : AUTOCOMPLETE_DEATH_PAGINATION_TEST;
            dispatch({
              type: actionType,
              payload: res.data
            });
          }
        }
      } catch (err) {
        if (!isCancel(err)) {
          dispatch({
            type: page === 1 ? AUTOCOMPLETE_ERROR : AUTOCOMPLETE_PAGINATION_ERROR,
            payload: { msg: err }
          });
        }
      }
    };

export const clearOptionsPayload = (type) => (dispatch) => {
  try {
    if (type === "birthPlace") {
      dispatch({
        type: CLEAR_BIRTH_OPTIONS
      });
    } else if (type === "residenceLocation") {
      dispatch({
        type: CLEAR_RESIDENCE_OPTIONS
      });
    } else {
      dispatch({
        type: CLEAR_DEATH_OPTIONS
      });
    }
  } catch (err) {
    if (!isCancel(err)) {
      dispatch({
        type: AUTOCOMPLETE_ERROR,
        payload: { msg: err }
      });
    }
  }
};

export const getAutocompleteRequest = () => (dispatch) => {
  dispatch({
    type: AUTOCOMPLETE_REQUEST
  });
};

export const addParent =
  (selectedNode, preAdd = false) =>
    async (dispatch) => {
      try {
        const preAddPayload = getPreAddPayload(preAdd);
        let res, res_isLiving, parentPayload;
        if (selectedNode.isLiving) {
          res_isLiving = await apiRequest(
            GET,
            `Persons/${selectedNode.treeId}/${selectedNode.childId}/parent/getlivingstatusofapersontobeadded`
          );
          let node = {
            ...selectedNode,
            isLiving: res_isLiving.data
          };
          parentPayload = getParentPayload(node);
        } else {
          parentPayload = getParentPayload(selectedNode);
        }

        dispatch({
          type: SET_MODAL_DATA,
          payload: {
            ...parentPayload,
            ...preAddPayload
          }
        });

        if (selectedNode.fetchSiblings)
          res = await apiRequest(
            GET,
            `Persons/${selectedNode.treeId}/${selectedNode.childId}/SiblingsSharingSingleParent/${selectedNode.parentId}`
          );
        const result = getValuesForCheckBoxes(res ? res.data.SiblingInfo : []);

        dispatch({
          type: ADD_PARENT,
          payload: result
        });
      } catch (err) {
        dispatch({
          type: FAMILY_ERROR,
          payload: { msg: err }
        });
      }
    };

export const saveParent = (modalPerson, preAdd, onSavePerson) => async (dispatch, getState) => {
  try {
    const parentData = saveParentPayload(modalPerson);
    if (
      trimString(parentData.parent.givenName) === "" &&
      trimString(parentData.parent.surname) === ""
    ) {
      dispatch(addMessage("First or last name is required.", "error"));
    } else {
      dispatch({ type: SAVING_MODAL });

      dispatch({ type: PERSON_LOADING });
      dispatch({ type: FETCH_PARENTS });
      await apiRequest(POST, `Persons/parent`, parentData);
      onSavePerson(modalPerson.id);
      removePreReload(preAdd);
      if (window.location.href.includes("/stories")) {
        const rightPanelDetails = getState().story.rightPanelDetails;
        //write a function to update rightPanelDetails with modalPerson object in it
        const panelDetails = updateParentsInStoryTab(modalPerson, rightPanelDetails);
        dispatch({
          type: GETRIGHTPANELDETAILS_SUCCESS,
          payload: panelDetails
        });
      }
      let refetch = refetchLogic(modalPerson);
      dispatch({ type: SAVE_PARENT, payload: refetch });
      let url = `/family/person-page/details/${modalPerson.treeId}/${modalPerson.id}`;
      setTimeout(() => {
        dispatch(
          addMessage(
            `${modalPerson.firstName} ${modalPerson.lastName} has been saved.`,
            "success",
            { url }
          )
        );
      }, 2000);
      dispatch({ type: SAVED_MODAL });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
    dispatch(addMessage("Sorry, person couldn't be saved. Please try again.", "error"));
  }
};

export const addIndividual = (individualPayload) => (dispatch) => {
  try {
    dispatch({
      type: SET_MODAL_DATA,
      payload: individualPayload
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};
export const saveIndividual = (_modalPerson, addNewPeopleFunc) => async (dispatch) => {
  try {
    dispatch({ type: SAVING_MODAL });

    const parentData = saveIndividualPayload(_modalPerson);
    const resultApi = await apiRequest(POST, `Persons/person`, parentData);
    if (resultApi) {
      const responseData = await apiRequest(GET, `Persons/${_modalPerson.id}/info`);
      if (responseData) {
        addNewPeopleFunc(responseData.data);
      }
    }
    setTimeout(() => {
      dispatch(
        addMessage(`${_modalPerson.firstName} ${_modalPerson.lastName} has been saved.`, "success")
      );
    }, 2000);

    dispatch({ type: SAVED_MODAL });
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
    dispatch(addMessage("Sorry, person couldn't be saved. Please try again." + err, "error"));
  }
};
const saveNonFamilyPerson = async (modalPerson, addNewPeopleFunc) => {
  const response = await apiRequest(GET, `Persons/${modalPerson.id}/info`);
  if (response) {
    addNewPeopleFunc && addNewPeopleFunc(response.data);
  }
};
const getConnectionName = (name) => {
  const nameData = {
    friend: "Friend",
    classmate: "Classmate",
    neighbor: "Neighbor",
    "military comrade": "Military Comrade",
    colleague: "Colleague",
    pet: "Pet",
    "fellow congregant": "Fellow Congregant"
  };
  return nameData[name] ? nameData[name] : "";
};
const getConection = (values) => {
  if (values.sr === "other") {
    return {
      connectionType: "Other",
      customType: values.rn
    };
  } else {
    return {
      connectionType: getConnectionName(values.sr)
    };
  }
};
export const saveNonFamily =
  (modalPerson, values, addNewPeopleFunc, individualPerson, handleCancel, propsid) =>
    async (dispatch) => {
      try {
        dispatch({ type: SAVING_MODAL });
        const parentData = saveIndividualPayload(modalPerson);
        let newDataJson = {
          PersonId1: modalPerson.id,
          PersonId2: values?.pn?.id,
          Person1ContributorId: getOwner(),
          Person2ContributorId: values?.pn?.ownerId,
          ConnectionType: values.sr === "other" ? values.rn : values.sr
        };
        const resultParentData =
          !modalPerson.added && (await apiRequest(POST, `Persons/person`, parentData));
        if (!individualPerson) {
          const result = await apiRequest(POST, `Persons/AddNonFamilyRelationship`, newDataJson);
          if (result) {
            saveNonFamilyPerson(modalPerson, addNewPeopleFunc);
            propsid &&
              dispatch({
                type: APPEND_RELATIONSHIP,
                payload: {
                  personId: modalPerson.id,
                  givenName: modalPerson.firstName,
                  surname: modalPerson.lastName,
                  gender: modalPerson.gender,
                  ...getConection(values),
                  birthDate: modalPerson.birth,
                  deathDate: modalPerson.death,
                  profileImageUrl: "",
                  isLiving: modalPerson.isLiving,
                  treeId: BLANKID
                }
              });
          }
        } else {
          if (resultParentData) {
            saveNonFamilyPerson(modalPerson, addNewPeopleFunc);
          }
        }

        setTimeout(() => {
          dispatch(
            addMessage(`${modalPerson.firstName} ${modalPerson.lastName} has been saved.`, "success")
          );
        }, 2000);

        dispatch({ type: SAVED_MODAL });
        handleCancel();
      } catch (err) {
        dispatch({
          type: SAVE_ERROR,
          payload: { msg: err }
        });
        dispatch(addMessage("Sorry, person couldn't be saved. Please try again." + err, "error"));
        handleCancel();
      }
    };

export const addSpouse =
  (selectedNode, preAdd = false) =>
    async (dispatch) => {
      try {
        let res, res_isLiving, modalPayload;
        const preAddPayload = getPreAddPayload(preAdd);
        if (selectedNode.isLiving) {
          res_isLiving = await apiRequest(
            GET,
            `Persons/${selectedNode.treeId}/${selectedNode.treePersonId}/spouse/getlivingstatusofapersontobeadded`
          );
          let node = {
            ...selectedNode,
            isLiving: res_isLiving.data
          };
          modalPayload = getSpousePayload(node);
        } else {
          modalPayload = getSpousePayload(selectedNode);
        }
        dispatch({
          type: SET_MODAL_DATA,
          payload: {
            ...modalPayload,
            ...preAddPayload
          }
        });
        if (selectedNode.fetchChildren)
          res = await apiRequest(
            GET,
            `Persons/${selectedNode.treeId}/${selectedNode.treePersonId}/GetDirectChildren`
          );

        const result = getSpouseAndChildrenPayload(selectedNode, res ? res.data.SiblingInfo : []);

        dispatch({
          type: ADD_SPOUSE,
          payload: result
        });
      } catch (err) {
        dispatch({
          type: FAMILY_ERROR,
          payload: { msg: err }
        });
      }
    };

export const saveSpouse = (nodePerson, modalPerson, preAdd, onSavePerson) => async (dispatch) => {
  try {
    const spouseData = saveSpousePayload(nodePerson, modalPerson);
    if (
      trimString(spouseData.spouse.givenName) === "" &&
      trimString(spouseData.spouse.surname) === ""
    ) {
      dispatch(addMessage("First or last name is required.", "error"));
    } else {
      dispatch({ type: SAVING_MODAL });

      dispatch({ type: PERSON_LOADING });
      dispatch({ type: FETCH_SPOUSES });
      await apiRequest(POST, `Persons/spouse`, spouseData);
      onSavePerson(modalPerson.id);
      removePreReload(preAdd);
      let refetch = refetchLogic(modalPerson);
      dispatch({ type: SAVE_SPOUSE, payload: refetch });
      let url = `/family/person-page/details/${modalPerson.treeId}/${modalPerson.id}`;
      setTimeout(() => {
        dispatch(
          addMessage(
            `${modalPerson.firstName} ${modalPerson.lastName} has been saved.`,
            "success",
            { url }
          )
        );
      }, 1000);

      dispatch({ type: SAVED_MODAL });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
    dispatch(addMessage("Sorry, person couldn't be saved. Please try again.", "error"));
  }
};

export const addSibling =
  (selectedNode, preAdd = false) =>
    async (dispatch) => {
      try {
        let res = false,
          siblingPayload,
          res_isLiving;
        const { treeId, primaryPersonId } = selectedNode;
        const preAddPayload = getPreAddPayload(preAdd);
        if (selectedNode.isLiving) {
          res_isLiving = await apiRequest(
            GET,
            `Persons/${selectedNode.treeId}/${selectedNode.primaryPersonId}/sibling/getlivingstatusofapersontobeadded`
          );
          let node = {
            ...selectedNode,
            isLiving: res_isLiving.data
          };
          siblingPayload = getSiblingPayload(node);
        } else {
          siblingPayload = getSiblingPayload(selectedNode);
        }

        dispatch({
          type: SET_MODAL_DATA,
          payload: {
            ...siblingPayload,
            ...preAddPayload
          }
        });

        if (selectedNode.fetchParents)
          res = await apiRequest(
            GET,
            `persons/${treeId}/${primaryPersonId}/parentsinfo?addUnknownParents=1`
          );

        const result = getSiblingDropdown(res ? res.data.Parents : []);

        dispatch({
          type: ADD_SIBLING,
          payload: result
        });
      } catch (err) {
        dispatch({
          type: FAMILY_ERROR,
          payload: { msg: err }
        });
      }
    };

export const saveSibling = (nodePerson, modalPerson, preAdd, onSavePerson) => async (dispatch) => {
  try {
    const siblingData = saveSiblingPayload(nodePerson, modalPerson);
    if (
      trimString(siblingData.child.givenName) === "" &&
      trimString(siblingData.child.surname) === ""
    ) {
      dispatch(addMessage("First or last name is required.", "error"));
    } else if (
      siblingData.existingParentIds.length === 1 &&
      siblingData.NewParent &&
      trimString(siblingData.NewParent.givenName) === "" &&
      trimString(siblingData.NewParent.surname) === ""
    )
      dispatch(addMessage("Unknown parent's first or last name is required.", "error"));
    else {
      dispatch({ type: SAVING_MODAL });

      dispatch({ type: PERSON_LOADING });
      dispatch({ type: FETCH_PARENTS });
      await apiRequest(POST, `Persons/sibling`, siblingData);
      onSavePerson(modalPerson.id);
      removePreReload(preAdd);
      let refetch = refetchLogic(modalPerson);
      if (modalPerson.nodeType === "FOCUS") {
        setFocusSiblingAdded(true);
      }
      dispatch({ type: SAVE_SIBLING, payload: refetch });
      if (modalPerson.pfirstName || modalPerson.plastName) {
        let url1 = `/family/person-page/details/${modalPerson.treeId}/${siblingData.NewParent.id}`;
        setTimeout(() => {
          dispatch(
            addMessage(
              `${modalPerson.pfirstName} ${modalPerson.plastName} has been saved.`,
              "success",
              { url: url1 }
            )
          );
        }, 1000);
      }
      let url = `/family/person-page/details/${modalPerson.treeId}/${modalPerson.id}`;
      dispatch(
        addMessage(`${modalPerson.firstName} ${modalPerson.lastName} has been saved.`, "success", {
          url
        })
      );

      dispatch({ type: SAVED_MODAL });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
    dispatch(addMessage("Sorry, person couldn't be saved. Please try again.", "error"));
  }
};

export const addChild =
  (selectedNode, preAdd = false) =>
    async (dispatch) => {
      try {
        let res_isLiving, spousesPayload;
        const preAddPayload = getPreAddPayload(preAdd);
        if (selectedNode.isLiving) {
          res_isLiving = await apiRequest(
            GET,
            `Persons/${selectedNode.treeId}/${selectedNode.treePersonId}/child/getlivingstatusofapersontobeadded`
          );
          let node = {
            ...selectedNode,
            isLiving: res_isLiving.data
          };
          spousesPayload = getChildPayload(node);
        } else {
          spousesPayload = getChildPayload(selectedNode);
        }
        dispatch({
          type: SET_MODAL_DATA,
          payload: {
            ...spousesPayload,
            ...preAddPayload
          }
        });
        const res = await apiRequest(
          GET,
          `Persons/${selectedNode.treeId}/${selectedNode.treePersonId}/spouses`
        );

        const result = getParentAndSpousesPayload(selectedNode, res ? res?.data?.Spouses : []);

        dispatch({
          type: ADD_CHILD,
          payload: result
        });
      } catch (err) {
        dispatch({
          type: FAMILY_ERROR,
          payload: { msg: err }
        });
      }
    };

export const saveChild = (nodePerson, modalPerson, preAdd, onSavePerson) => async (dispatch) => {
  try {
    const childData = saveSiblingPayload(nodePerson, modalPerson); //Payload is same for sibling & child
    if (
      trimString(childData.child.givenName) === "" &&
      trimString(childData.child.surname) === ""
    ) {
      dispatch(addMessage("First or last name is required.", "error"));
    } else if (
      childData.existingParentIds.length === 1 &&
      childData.NewParent &&
      trimString(childData.NewParent.givenName) === "" &&
      trimString(childData.NewParent.surname) === ""
    )
      dispatch(addMessage("Unknown parent's first or last name is required.", "error"));
    else {
      dispatch({ type: SAVING_MODAL });

      dispatch({ type: PERSON_LOADING });
      dispatch({ type: FETCH_SPOUSES });
      await apiRequest(POST, `Persons/child`, childData);
      removePreReload(preAdd);
      onSavePerson(modalPerson.id);
      let refetch = refetchLogic(modalPerson);
      if (nodePerson.relationAdded === "ADD_CHILD") {
        if (nodePerson.generation === 1) {
          setFocusSiblingAdded(true);
        } else {
          setFocusSiblingAdded(false);
        }
      }
      if (modalPerson.pfirstName || modalPerson.plastName) {
        let url1 = `/family/person-page/details/${modalPerson.treeId}/${childData.NewParent.id}`;
        setTimeout(() => {
          dispatch(
            addMessage(
              `${modalPerson.pfirstName} ${modalPerson.plastName} has been saved.`,
              "success",
              { url: url1 }
            )
          );
        }, 1000);
      }
      dispatch({ type: SAVE_CHILD, payload: refetch });
      let url = `/family/person-page/details/${modalPerson.treeId}/${modalPerson.id}`;
      dispatch(
        addMessage(`${modalPerson.firstName} ${modalPerson.lastName} has been saved.`, "success", {
          url
        })
      );

      dispatch({ type: SAVED_MODAL });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
    dispatch(addMessage("Sorry, person couldn't be saved. Please try again.", "error"));
  }
};

export const cancelModal = () => (dispatch) => {
  try {
    dispatch({ type: CANCEL_MODAL });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const updateFamily = (data) => (dispatch) => {
  try {
    dispatch({ type: FAMILY_LOADING });

    const res = data;

    setTimeout(() => {
      dispatch({
        type: UPDATE_FAMILY,
        payload: res
      });
    }, 100);
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const addProfileImage =
  (imagePayload, personCheck, FF_HandleDerivedImagesAtBE, fam) => async (dispatch) => {
    try {
      let url;
      dispatch({ type: IMAGE_LOADING });
      if (imagePayload?.petId) {
        url = `Media/uploadpetprofileimage`;
        dispatch({
          type: ADD_PET_THUMBNAIL,
          payload: imagePayload
        });
      } else if (personCheck) {
        url = `Media/uploadprofileimage`;
        dispatch({
          type: ADD_PERSON_THUMBNAIL,
          payload: imagePayload
        });
      } else {
        url = `Media/uploadprofileimage`;
        dispatch({ type: SET_NEW_FAMILY_PAYLOAD, payload: fam });
      }
      const payload = imageUploadPayload(imagePayload, FF_HandleDerivedImagesAtBE);
      await apiRequest(POST, url, payload);
      dispatch({ type: SAVE_PROFILE_IMAGE });
      dispatch({ type: IMAGE_UNLOADING });
    } catch (err) {
      dispatch({
        type: SAVE_ERROR,
        payload: { msg: err }
      });
      dispatch({ type: IMAGE_UNLOADING });
    }
  };

export const deleteProfileImage = (individualId, petId) => async (dispatch) => {
  try {
    dispatch({ type: IMAGE_LOADING });
    if (petId) {
      await apiRequest(DELETE, `Pet/deletepetprofileimage/${individualId}`);
      dispatch({ type: DELETE_PET_PROFILE_IMAGE });
    } else {
      await apiRequest(DELETE, `Persons/deleteprofileimage/${individualId}`);
      dispatch({ type: SAVE_PROFILE_IMAGE });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};

export const getProfileImage =
  (profileImageId, heroImage, queryParam = "") =>
    async (dispatch) => {
      try {
        dispatch({ type: FETCHING_IMAGE });
        const res = await apiRequest(GET, `Media/${profileImageId}/OriginalImage${queryParam}`);
        if (!heroImage) {
          dispatch({
            type: GET_PROFILE_IMAGE,
            payload: res.data
          });
        } else {
          dispatch({
            type: GET_HERO_IMAGE,
            payload: res.data
          });
        }
      } catch (err) {
        dispatch({
          type: FAMILY_ERROR,
          payload: { msg: err }
        });
      }
    };

export const editProfileImage = (imagePayload, personCheck, pet) => async (dispatch) => {
  try {
    dispatch({ type: IMAGE_LOADING });
    if (personCheck) {
      dispatch({
        type: ADD_PERSON_THUMBNAIL,
        payload: imagePayload
      });
      dispatch({ type: IMAGE_UNLOADING });
    }
    const payload = editImageUploadPayload(imagePayload);
    if (pet) {
      await apiRequest(POST, `Media/editpetprofileimage`, payload);
    } else {
      await apiRequest(POST, `Media/editprofileimage`, payload);
    }
    dispatch({ type: SAVE_PROFILE_IMAGE });
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearImage = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_IMAGE });
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};

export const showImage = () => (dispatch) => {
  try {
    dispatch({ type: SHOW_IMAGE });
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};

export const getRepresentInfo = (userId, treeId, primaryPersonId) => async (dispatch) => {
  try {
    const res = await apiRequest(GET, `Trees/${userId}/${treeId}/representingpersoninfo`);
    if (primaryPersonId === res.data.treePersonId && res.data.profileImageUrl !== null) {
      dispatch({ type: REFETCH_PAGE_AFTER_UPLOAD });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};

export const getPersonSpouses = (selectedNode) => async (dispatch) => {
  try {
    const eventPayload = getRelatedEventPayload(selectedNode);
    dispatch({
      type: SET_EVENT_MODAL_DATA,
      payload: eventPayload
    });
    const res = await apiRequest(
      GET,
      `Persons/${selectedNode.treeId}/${selectedNode.treePersonId}/spouses`
    );
    const result = getSpouseOptions(res.data.Spouses);
    dispatch({
      type: ADD_RELATED_EVENT,
      payload: result
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const getDirectChildren = (selectedNode) => async (dispatch) => {
  try {
    const res = await apiRequest(
      GET,
      `Persons/${selectedNode.treeId}/${selectedNode.id}/GetDirectChildren`
    );
    const result = getValuesForCheckBoxes(res.data.SiblingInfo);
    dispatch({
      type: DIRECT_CHILDREN,
      payload: result
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const clearEventInfo = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_EVENT_INFO
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};
const isJsonString = (str) => {
  let jsonStr;
  try {
    jsonStr = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return jsonStr;
};
const getPreAddPayload = (preAdd) => {
  if (preAdd && localStorage.getItem("modalperson")) {
    const modalPerson = isJsonString(localStorage.getItem("modalperson"));
    return modalPerson ? { ...modalPerson, isLiving: modalPerson.isLiving.toString() } : {};
  } else {
    return {};
  }
};
const removePreReload = (preAdd) => {
  if (preAdd) {
    localStorage.removeItem("modalperson");
  }
};

export const getIsLivingStatus = (treeId, personId, relation) => async (dispatch) => {
  try {
    let res_isLiving = await apiRequest(
      GET,
      `Persons/${treeId}/${personId}/${relation}/getlivingstatusofapersontobeadded`
    );
    return res_isLiving.data;
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const getPersonSearchClue = (personId) => async (dispatch) => {
  try {
    let searchUrl = await apiRequest(GET, `persons/${personId}/personhint`);
    return searchUrl.data;
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const updatePersonHintcount = (personAndHintCount) => (dispatch, getState) => {
  const familyId = getState().family?.family?.id;
  const isFirstHintSeen = getState().user?.firstHintSeen;
  const personWithFirstHint = { ...personAndHintCount, firstHintSeen: !isFirstHintSeen };

  dispatch({
    type: UPDATE_HINT_COUNT_ITEM,
    payload: { personAndHintCount: personWithFirstHint, familyId }
  });
};

export const updatePotentialParent = (treeId, childId, gender) => (dispatch, getState) => {
  try {
    const familyTreeId = getState().family?.family?.treeInfo?.treeId;
    if (familyTreeId === treeId) {
      const family = getState().family?.family;
      const treeData = cloneDeep(family);
      let newFamily;
      let resultNode = "";
      if (treeData.id === childId) resultNode = treeData;
      if (resultNode === "") resultNode = findNodeItem(treeData.parents, childId, "parents");
      if (resultNode) {
        newFamily = upDatePotentialFamily(resultNode, treeData, gender);
        if (newFamily) dispatch({ type: SET_NEW_FAMILY_PAYLOAD, payload: newFamily });
      }
    }
  }
  catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  };
};

export const getPersonHintsCount = (pedigreeList, family) => async (dispatch, getState) => {
  try {
    let isFirstHintSeen = false,
      firstHintId;
    const familyId = family ? family.id : getState().family?.family.id;
    const isFirstHintSeenState = getState().user?.firstHintSeen;
    const personIds = pedigreeList.map((person) => {
      return `personIds=${person.id}`;
    });
    const strPersonIds = personIds.join("&");
    const res = await apiRequest(GET, `Persons/hintcount?${strPersonIds}`);
    const hintsResponse = res?.data?.hintCount ?? [];
    if (hintsResponse.length <= 0) {
      return;
    }
    const hints = hintsResponse?.reduce((acc, currentVal) => {
      if (!isFirstHintSeen && currentVal.hintCount > 0) {
        currentVal.firstHintSeen = true;
        isFirstHintSeen = true;
        firstHintId = currentVal.personId;
      } else currentVal.firstHintSeen = false;
      acc[currentVal.personId] = currentVal;
      return acc;
    }, {});

    if (!isFirstHintSeenState && firstHintId) {
      const collectionId = await getCollectionIdOfHighestScoringHint(firstHintId);
      dispatch({
        type: COLLECTIONID_HIGHEST_HINT,
        payload: collectionId
      });
    }

    const oldHintsCountPayload = getState().family?.hintsCountPayload;
    const newHintsCountPayload = {
      ...oldHintsCountPayload,
      [familyId]: {
        ...oldHintsCountPayload?.[familyId],
        ...hints
      }
    };

    if (hints && !_.isEqual(oldHintsCountPayload, newHintsCountPayload)) {
      dispatch({ type: SET_HINTS_PAYLOAD, payload: { hints, familyId } });
    }
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const getPersonNewspaperMatchesCount = (pedigreeList) => async (dispatch, getState) => {
  try {
    const familyId = getState().family?.family?.id;
    const userId = getState().user?.userId;
    const personIds = pedigreeList.map((person) => {
      return `${person.id}`;
    });
    const res = await apiRequest(POST, "Users/newspaper-matches/tree-counts", {
      personIds,
      userId
    });
    const matches = res?.data?.resultData ?? [];
    if (matches.length <= 0) {
      return;
    }

    if (matches) {
      dispatch({ type: SET_MATCHES, payload: { matches, familyId } });
    }
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

/**
 * @param {string[]} personIds
 */
export function requestHintGeneration(personIds) {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(REQUEST_HINT_GENERATION.REQUEST));
      await apiRequest(POST, HINT_GENERATION, personIds); // auto jiggler - no response needed
      dispatch(actionCreator(REQUEST_HINT_GENERATION.SUCCESS));
    } catch (err) {
      dispatch(actionCreator(REQUEST_HINT_GENERATION.FAILURE));
    }
  };
}

const computeCompletion = (payload) => {
  return (
    (Boolean(payload.firstName) || Boolean(payload.lastName)) +
    Boolean(payload.attributes.birth.rawDate) +
    Boolean(payload.attributes.birthLocation) +
    (payload?.residenceEvents.length > 0)
  );
};

const getPersonPayload = (treeProgressData) => {
  return {
    orderId: 1,
    name: Boolean(treeProgressData?.firstName) || Boolean(treeProgressData?.lastName),
    residence: treeProgressData?.residenceEvents.length > 0 ?? false,
    birthDate: Boolean(treeProgressData?.attributes.birth.rawDate),
    birthLocation: Boolean(treeProgressData?.attributes.birthLocation),
    check: computeCompletion(treeProgressData),
    tkey: "text.you",
    quickEditNode: getQuickEditNode(treeProgressData),
    detailBtnText: "btnText.AddDetailsForYourself",
    addDetailBtnText: "tooltip.key.yourself",
    open: false,
    id: treeProgressData?.id,
    path: ""
  };
};

const getTreeData = (treeProgressData) => {
  return {
    percentageComplete: treeProgressData?.percentageComplete,
    totalDataPoints: treeProgressData?.totalDataPoints
  };
};

const getParentsRelation = (parent) => {
  const parentsRelationMap = {
    0: "text.YourFather",
    1: "text.YourMother",
    "0/0": "text.YourPaternalGrandfather",
    "0/1": "text.YourPaternalGrandmother",
    "1/0": "text.YourMaternalGrandfather",
    "1/1": "text.YourMaternalGrandmother"
  };
  return parentsRelationMap[parent.attributes.path];
};

const getOrderId = (parent) => {
  const paths = {
    0: 2,
    1: 3,
    "0/0": 4,
    "0/1": 5,
    "1/0": 6,
    "1/1": 7
  };
  return paths[parent.attributes.path];
};

const getBtnText = (parent) => {
  const paths = {
    0: "btnText.AddYourFather",
    1: "btnText.AddYourMother",
    "0/0": "btnText.AddYourPaternalGrandfather",
    "0/1": "btnText.AddYourPaternalGrandmother",
    "1/0": "btnText.AddYourMaternalGrandfather",
    "1/1": "btnText.AddYourMaternalGrandmother"
  };
  return paths[parent.attributes.path];
};

const getDetailBtnText = (parent) => {
  const paths = {
    0: "btnText.AddDetailsForYourFather",
    1: "btnText.AddDetailsForYourMother",
    "0/0": "btnText.AddDetailsForYourPGrandfather",
    "0/1": "btnText.AddDetailsForYourPGrandmother",
    "1/0": "btnText.AddDetailsForYourMGrandfather",
    "1/1": "btnText.AddDetailsForYourMGrandmother"
  };
  return paths[parent.attributes.path];
};

const getAddDetailText = (parent) => {
  const paths = {
    0: "tooltip.key.yourFather",
    1: "tooltip.key.yourMother",
    "0/0": "tooltip.key.yourPaternalGrandfather",
    "0/1": "tooltip.key.yourPaternalGrandmother",
    "1/0": "tooltip.key.yourMaternalGrandfather",
    "1/1": "tooltip.key.yourMaternalGrandmother"
  };
  return paths[parent.attributes.path];
};

const getQuickEditNode = (currentParent) => {
  return {
    id: currentParent.id,
    parentId: currentParent.parentId,
    cFilialId: currentParent?.attributes?.cFilialId,
    type: "medium-img",
    path: currentParent?.attributes?.path,
    title: "",
    firstName: currentParent?.firstName,
    firstNameWithInitials: currentParent?.firstName,
    lastName: currentParent?.lastName,
    isLiving: currentParent?.isLiving,
    gender: currentParent?.attributes?.gender,
    birth: currentParent?.attributes?.birth?.rawDate,
    birthPlace: currentParent?.attributes?.birthLocation,
    death: currentParent?.attributes?.death?.rawDate,
    deathPlace: currentParent?.attributes?.deathLocation,
    imgsrc: currentParent?.attributes?.imgsrc,
    relatedParentIds: currentParent?.attributes?.relatedParentIds,
    residenceEvents: currentParent?.residenceEvents
  };
};

const getQuickEditAddNode = (currentParent) => {
  return {
    id: currentParent.id,
    parentId: currentParent.parentId,
    cFilialId: currentParent?.attributes?.cFilialId,
    type: "medium-img",
    path: currentParent?.attributes?.path,
    title: "",
    firstName: currentParent?.firstName,
    firstNameWithInitials: currentParent?.firstName,
    lastName: currentParent?.lastName,
    isLiving: currentParent?.isLiving,
    gender: currentParent?.attributes?.gender,
    birth: currentParent?.attributes?.birth?.rawDate,
    birthPlace: currentParent?.attributes?.birthLocation,
    death: currentParent?.attributes?.death?.rawDate,
    deathPlace: currentParent?.attributes?.deathLocation,
    imgsrc: currentParent?.attributes?.imgsrc,
    relatedParentIds: currentParent?.attributes?.relatedParentIds,
    residenceEvents: currentParent?.upDatedResidenceEvents
  };
};

const getOptimisticQuickEditNode = (currentParent) => {
  return {
    id: currentParent.id,
    parentId: currentParent.parentId,
    cFilialId: currentParent?.attributes?.cFilialId,
    type: "medium-img",
    path: currentParent?.attributes?.path,
    title: "",
    firstName: currentParent?.firstName,
    firstNameWithInitials: currentParent?.firstName,
    lastName: currentParent?.lastName,
    isLiving: currentParent?.isLiving,
    gender: currentParent?.gender,
    birth: currentParent?.birth,
    birthPlace: currentParent?.birthPlace,
    death: currentParent?.death,
    deathPlace: currentParent?.deathPlace,
    imgsrc: currentParent?.attributes?.imgsrc,
    relatedParentIds: currentParent?.attributes?.relatedParentIds,
    residenceEvents: currentParent?.upDatedResidenceEvents
  };
};

const getSelectedNode = (currentParent) => {
  return {
    id: currentParent.id,
    parentId: currentParent.parentId,
    cFilialId: currentParent?.attributes?.cFilialId,
    type: "medium-next-gen",
    path: currentParent?.attributes?.path,
    title: currentParent?.attributes?.title,
    firstName: "",
    firstNameWithInitials: "",
    lastName: "",
    isLiving: true,
    gender: currentParent?.attributes?.gender,
    birth: "",
    birthPlace: "",
    death: "",
    deathPlace: "",
    imgsrc: "",
    relatedParentIds: null,
    residenceEvents: []
  };
};

const getParentsPayload = (parentsList, allParents) => {
  parentsList.forEach((currentParent) => {
    allParents.push({
      id: currentParent.id,
      orderId: getOrderId(currentParent),
      name: Boolean(currentParent.firstName) || Boolean(currentParent.lastName),
      firstName: Boolean(currentParent.firstName),
      lastName: Boolean(currentParent.lastName),
      birthDate: Boolean(currentParent.attributes.birth.rawDate),
      birthLocation: Boolean(currentParent.attributes.birthLocation),
      residence: currentParent?.residenceEvents.length > 0 ?? false,
      tkey: getParentsRelation(currentParent),
      check: computeCompletion(currentParent),
      node: getSelectedNode(currentParent),
      quickEditNode: getQuickEditNode(currentParent),
      btnText: getBtnText(currentParent),
      detailBtnText: getDetailBtnText(currentParent),
      addDetailBtnText: getAddDetailText(currentParent),
      open: false,
      path: currentParent.attributes.path
    });

    if (currentParent.parents?.length > 0) {
      getParentsPayload(currentParent.parents, allParents);
    }
  });

  return allParents;
};

const sampleCheckList = [
  {
    path: "0",
    orderId: 2,
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    tkey: "text.YourFather",
    btnText: "btnText.AddYourFather",
    accordionTooltipText: "",
    open: false
  },
  {
    path: "1",
    orderId: 3,
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    tkey: "text.YourMother",
    btnText: "btnText.AddYourMother",
    accordionTooltipText: "",
    open: false
  },
  {
    path: "0/0",
    orderId: 4,
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    tkey: "text.YourPaternalGrandfather",
    btnText: "btnText.AddYourPaternalGrandfather",
    accordionTooltipText: "btnText.AddPaternalRelation",
    open: false
  },
  {
    path: "0/1",
    orderId: 5,
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    tkey: "text.YourPaternalGrandmother",
    btnText: "btnText.AddYourPaternalGrandmother",
    accordionTooltipText: "btnText.AddPaternalRelation",
    open: false
  },
  {
    path: "1/0",
    orderId: 6,
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    tkey: "text.YourMaternalGrandfather",
    btnText: "btnText.AddYourMaternalGrandfather",
    accordionTooltipText: "btnText.AddMaternalRelation",
    open: false
  },
  {
    path: "1/1",
    orderId: 7,
    name: false,
    birthDate: false,
    birthLocation: false,
    residence: false,
    check: 0,
    tkey: "text.YourMaternalGrandmother",
    btnText: "btnText.AddYourMaternalGrandmother",
    accordionTooltipText: "btnText.AddMaternalRelation",
    open: false
  }
];

export const getPreferenceRelation = (accordionList) => {
  return accordionList.find((ele) => ele.check === 0);
};

export const getPreferenceDetail = (accordionList) => {
  return accordionList.find((ele) => ele.check < 4);
};

export const getPreferenceDetailFocus = (preferenceDetail) => {
  switch (true) {
    case !preferenceDetail?.residence:
      return "residence";
    case !preferenceDetail?.birthDate:
      return "birthDate";
    case !preferenceDetail?.birthLocation:
      return "birthPlace";
    default: //do nothing
  }
};

const formTreeProgressData = (treeProgressData) => {
  const treeData = getTreeData(treeProgressData);
  const personPayload = getPersonPayload(treeProgressData);
  const parents = getParentsPayload(treeProgressData.parents, []);
  const parentsWithSamplePayload = [...parents, ...sampleCheckList];
  const uniqueParents = parentsWithSamplePayload.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item.orderId === current.orderId)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  const checkListPayload = [...uniqueParents, personPayload];
  const purePayload = checkListPayload.filter((ele) => ele.orderId !== undefined);
  let sortedCheckListPayload = purePayload.sort((a, b) => {
    return a.orderId - b.orderId;
  });
  let openedAccordian = false;
  const preferenceRelation = getPreferenceRelation(sortedCheckListPayload);
  const preferenceDetail = getPreferenceDetail(sortedCheckListPayload);
  const preferenceDetailFocus = getPreferenceDetailFocus(preferenceDetail);
  sortedCheckListPayload = sortedCheckListPayload.map((item) => {
    if (item.check < 4 && openedAccordian === false) {
      openedAccordian = true;
      return { ...item, open: true };
    }
    return item;
  });
  return {
    treeData,
    preferenceRelation,
    preferenceDetail,
    preferenceDetailFocus,
    sortedCheckListPayload
  };
};

export const getTreeProgressData = (treeId, personId) => async (dispatch, getState) => {
  try {
    const isGedcomUploaded = getState().gedcom.isGedcomUploaded;

    const treeProgressData = await apiRequest(
      GET,
      `Persons/pedigreeprogressmeterinfo/${treeId}/${personId}`
    );
    const treeProgressPayload = formTreeProgressData(treeProgressData.data);
    dispatch({ type: TREE_PROGRESS_PAYLOAD, payload: treeProgressPayload });

    if (treeProgressPayload.treeData.percentageComplete === 100 && isGedcomUploaded) {
      await apiRequest(POST, `Trees/${treeId}/buildguidancecompleted`);
      dispatch({ type: TREE_PROGRESS_PAYLOAD, payload: null });
    }
    dispatch({ type: SET_IS_GEDCOM_UPLOADED });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const refetchTreeProgress = () => (dispatch) => {
  try {
    dispatch({
      type: REFETCH_TREE_PROGRESS
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const closeTreeProgressModal = () => (dispatch) => {
  dispatch({ type: CLOSE_TREE_PROGRESS_MODAL });
};

export const updateFirstHintSeenState = () => (dispatch) => {
  dispatch({ type: UPDATE_FIRST_HINT_SEEN });
};

export const setFirstHintSeen = () => async (dispatch) => {
  try {
    await apiRequest(POST, `User/firstHintSeen`);
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};
const getCollectionIdOfHighestScoringHint = (personId) => {
  return apiRequest("GET", `Persons/${personId}/collectionidofhighestscoringhint`).then((res) => {
    return res.data || {};
  });
};

export const setCollectionIdOfHighestScoringHint = (collectionId) => (dispatch) => {
  try {
    dispatch({
      type: COLLECTIONID_HIGHEST_HINT,
      payload: collectionId
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const getParsedDate = (birthDate) => {
  return apiRequest("GET", `Trees/GetParsedDate/${birthDate}`).then((res) => {
    return res.data || {};
  });
};

export const animateNewNode = (parentData) => (dispatch) => {
  try {
    dispatch({
      type: NEW_NODE_HIGHTLIGHT,
      payload: parentData
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const setSelectedParentSide = (parentSide) => (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SELECTED_PARENT_SIDE, payload: parentSide });
  }, 500);
};

export const updateSelectedParentSide = () => (dispatch) => {
  setTimeout(() => {
    dispatch({ type: UPDATE_SELECTED_PARENT_SIDE });
  }, 500);
};

export const closeAddGrandParentModal = () => (dispatch) => {
  try {
    dispatch({
      type: CLOSE_ADD_GRANDPARENT_MODAL
    });
  } catch (err) {
    dispatch({
      type: FAMILY_ERROR,
      payload: { msg: err }
    });
  }
};

export const getPotentialParents = (personId, gender) => {
  return apiRequest("GET", `Persons/newpersonhint/${personId}/${gender}`).then((res) => {
    return res.data || {};
  });
};

export const rejectPotentialParent = (payload) => async (dispatch) => {
  try {
    await apiRequest(POST, `Persons/rejectnewPersonhint`, payload);
  } catch (err) {
    dispatch({
      type: SAVE_ERROR,
      payload: { msg: err }
    });
  }
};

export const acceptPotentialParent = (childData,
  parentData,
  treeId,
  newTreeProgressParentPath,
  onSaveParent) => async (dispatch, getState) => {
    try {
      const currentTreeProgress = getState().family.treeProgressPayload;
      const payload = addPotentialPersonPayload(childData, parentData, treeId);
      await apiRequest(POST, `Persons/potentialparent`, payload);
      if (currentTreeProgress) {
        optimisticUpdateTreeProgressForAddPerson({
          parentData,
          currentTreeProgress,
          newTreeProgressParentPath,
          treeId,
          dispatch
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_ERROR,
        payload: { msg: err }
      });
    }
  };
