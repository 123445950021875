import { Auth0ContextInterface } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, Method } from "axios";
import { checkErrorUrl } from "utils";
import { REACT_APP_API, REACT_APP_CLIENT_ID } from "utils/env";
import { v4 as uuidv4 } from "uuid";
import { getAccessToken, getGoogleSessionId, removeCookies, setAccessToken } from "../../services";
import store, { AppDispatch } from "./../store";

const BASE_URL = REACT_APP_API;
const CLIENT_ID = REACT_APP_CLIENT_ID;

const httpClient = axios.create();

const getCustomHeader = () => {
  const accessToken = getAccessToken();
  const bearer = `Bearer ${accessToken}`;
  return {
    "wa-clientId": CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId(),
    Authorization: bearer
  };
};

export const getApiCancelToken = () => {
  return axios.CancelToken.source();
};

export const isCancel = (err: any) => {
  return axios.isCancel(err);
};

const refreshAuthOToken = (
  getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"]
) => {
  return new Promise((resolve) => {
    getAccessTokenSilently({
      cacheMode: "off"
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error) {
          removeCookies();
        }
        resolve(error);
      });
  });
};

export function addAxiosErrorInterceptor(
  getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"],
  dispatch: AppDispatch
) {
  httpClient.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      if (error.config && error.response && error.response.status === 401) {
        return refreshAuthOToken(getAccessTokenSilently).then((token) => {
          setAccessToken(token);
          const bearer = `Bearer ${token}`;
          error.config.headers = {
            "wa-clientId": CLIENT_ID,
            "wa-requestId": uuidv4(),
            "wa-sessionId": getGoogleSessionId(),
            Authorization: bearer,
            "content-type": "application/json"
          };
          return axios.request(error.config);
        });
      }
      // if (error.config && error.response && error.response.status === 500) {
      //   dispatch({ type: "APP_ERROR_STATE" });
      // }

      return Promise.reject(error);
    }
  );
}

export const apiRequest = async (
  method: Method,
  url: string,
  data?: any,
  onUploadProgress?: (progressEvent: any) => void,
  source?: any,
  headers: Record<string, string> = {},
  handleGlobalErr: boolean = true
): Promise<any> => {
  try {
    const sourceJSON = source
      ? {
        cancelToken: source.token
      }
      : {};

    headers = {
      ...getCustomHeader(),
      ...headers
    };

    const onUploadProgressFn = (progressEvent: any) => {
      if (onUploadProgress) onUploadProgress(progressEvent);
    };

    const isUploadProgressEnabled = process.env.NODE_ENV !== 'test';
    const checkURL = await checkErrorUrl(url);

    const config: AxiosRequestConfig = {
      method,
      url: `${BASE_URL}/api/${checkURL}`,
      headers,
      data,
      ...sourceJSON,
      onUploadProgress: isUploadProgressEnabled ? onUploadProgressFn : undefined
    };

    return await axios(config);
  } catch (err: any) {
    if (handleGlobalErr && err.config && err?.response?.status === 500) {
      store.dispatch({ type: 'APP_ERROR_STATE', payload: handleGlobalErr });
    } else if (err.config && err?.response?.status === 500) {
      return err.response;
    }
    return Promise.reject(err);
  }
};