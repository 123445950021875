/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminUserCouponBulkgrantData,
  AdminUserCouponBulkgrantPayload,
  AdminUserCouponBulkrevokeData,
  AdminUserCouponBulkrevokePayload,
  AdminUserCouponGrantData,
  AdminUserCouponGrantParams,
  AdminUserCouponReloadData,
  AdminUserCouponReloadParams,
  AdminUserCouponRevokeData,
  AdminUserCouponRevokeParams,
  UserCouponGrantData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class UserCoupon extends HttpClient {
  /**
   * No description
   *
   * @tags UserCoupon
   * @name UserCouponGrant
   * @request POST:/api/UserCoupon/grant
   * @secure
   */
  userCouponGrant = (params: RequestParams = {}) =>
    this.request<UserCouponGrantData, any>({
      path: `/api/UserCoupon/grant`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserCoupon
   * @name AdminUserCouponBulkgrant
   * @request POST:/api/admin/UserCoupon/bulkgrant
   * @secure
   */
  adminUserCouponBulkgrant = (data: AdminUserCouponBulkgrantPayload, params: RequestParams = {}) =>
    this.request<AdminUserCouponBulkgrantData, any>({
      path: `/api/admin/UserCoupon/bulkgrant`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags UserCoupon
   * @name AdminUserCouponGrant
   * @request POST:/api/admin/UserCoupon/grant
   * @secure
   */
  adminUserCouponGrant = (query: AdminUserCouponGrantParams, params: RequestParams = {}) =>
    this.request<AdminUserCouponGrantData, any>({
      path: `/api/admin/UserCoupon/grant`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserCoupon
   * @name AdminUserCouponBulkrevoke
   * @request POST:/api/admin/UserCoupon/bulkrevoke
   * @secure
   */
  adminUserCouponBulkrevoke = (
    data: AdminUserCouponBulkrevokePayload,
    params: RequestParams = {}
  ) =>
    this.request<AdminUserCouponBulkrevokeData, any>({
      path: `/api/admin/UserCoupon/bulkrevoke`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags UserCoupon
   * @name AdminUserCouponRevoke
   * @request POST:/api/admin/UserCoupon/revoke
   * @secure
   */
  adminUserCouponRevoke = (query: AdminUserCouponRevokeParams, params: RequestParams = {}) =>
    this.request<AdminUserCouponRevokeData, any>({
      path: `/api/admin/UserCoupon/revoke`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags UserCoupon
   * @name AdminUserCouponReload
   * @request POST:/api/admin/UserCoupon/reload
   * @secure
   */
  adminUserCouponReload = (query: AdminUserCouponReloadParams, params: RequestParams = {}) =>
    this.request<AdminUserCouponReloadData, any>({
      path: `/api/admin/UserCoupon/reload`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
