/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RdRData, RdRParams } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Redirector extends HttpClient {
  /**
   * No description
   *
   * @tags Redirector
   * @name RdR
   * @request GET:/api/rd/r
   * @secure
   */
  rdR = (query: RdRParams, params: RequestParams = {}) =>
    this.request<RdRData, any>({
      path: `/api/rd/r`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    });
}
