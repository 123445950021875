/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  TopicAuthorityIdData,
  TopicAuthorityReloadData,
  TopicAuthoritySearchPageData,
  TopicAuthorityUrlData
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class TopicAuthority extends HttpClient {
  /**
   * No description
   *
   * @tags TopicAuthority
   * @name TopicAuthoritySearchPage
   * @request GET:/api/TopicAuthority/search/{requestId}/page/{pageNumber}/{searchString}
   * @secure
   */
  topicAuthoritySearchPage = (
    requestId: string,
    pageNumber: number,
    searchString: string,
    params: RequestParams = {}
  ) =>
    this.request<TopicAuthoritySearchPageData, any>({
      path: `/api/TopicAuthority/search/${requestId}/page/${pageNumber}/${searchString}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags TopicAuthority
   * @name TopicAuthorityUrl
   * @request GET:/api/TopicAuthority/url/{targetUrl}
   * @secure
   */
  topicAuthorityUrl = (targetUrl: string, params: RequestParams = {}) =>
    this.request<TopicAuthorityUrlData, any>({
      path: `/api/TopicAuthority/url/${targetUrl}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags TopicAuthority
   * @name TopicAuthorityId
   * @request GET:/api/TopicAuthority/id/{targetId}
   * @secure
   */
  topicAuthorityId = (targetId: string, params: RequestParams = {}) =>
    this.request<TopicAuthorityIdData, any>({
      path: `/api/TopicAuthority/id/${targetId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags TopicAuthority
   * @name TopicAuthorityReload
   * @request GET:/api/TopicAuthority/reload
   * @secure
   */
  topicAuthorityReload = (params: RequestParams = {}) =>
    this.request<TopicAuthorityReloadData, any>({
      path: `/api/TopicAuthority/reload`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
