import { RecurlyProvider } from "@recurly/react-recurly";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { I18nextProvider } from "react-i18next";

import { PublicClientApplication } from "@azure/msal-browser";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import GlobalLoader from "GlobalLoader";
import muiTheme from "muiTheme";
import { createRoot } from "react-dom/client";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  getLoggedInViaShareableID,
  getOfferSignUp,
  getPaymentCookies,
  getRootSignUp,
  getSignUpGroupPlanFlow,
  getSignUpGroupUser,
  getSignUpInRedirect,
  getSignUpUser,
  getUserFlow,
  getViaMarketingURL
} from "services";
import "tailwindcss/tailwind.css";
import { BUNDLEPLAN, FREE_TRIALPLAN, PLUSPLAN } from "utils/constant";
import { REACT_APP_ENV } from "utils/env";
import App from "./App";
import {
  msalConfigForLogin,
  msalConfigForOfferSignInUp,
  msalConfigForOfferSignUp,
  msalConfigForPayment,
  msalConfigForRootSignUp,
  msalConfigForSignInUpGroupProcess,
  msalConfigForSignInUpMarketingProcess,
  msalConfigForSignInUpStoriesProcess,
  msalConfigForSignUp,
  msalConfigForSignUpGroupProcess,
  msalConfigForSignUpGroupProcessSubscribe,
  msalConfigForSignUpMarketingProcess,
  msalConfigForSignUpSubscribe,
  msalConfigForStoryShareableSignInup
} from "./authConfig";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://bf210e55b77470c83b5a458962527c5d@o4507131953283072.ingest.us.sentry.io/4507145712435200",
  environment: REACT_APP_ENV,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const getRedirect = () => {
  const cookiesname = getPaymentCookies();
  switch (cookiesname) {
    case FREE_TRIALPLAN:
      return "/payment/trial";
    case BUNDLEPLAN:
      return "/payment/bundle";
    case PLUSPLAN:
      return "/payment";
    default:
      return "/payment";
  }
};
const getMsalInstance = () => {
  let userFlow = getUserFlow();
  let policy = msalConfigForLogin;
  let redirectUrl = getSignUpInRedirect();

  if (userFlow === "signUp") {
    if (getSignUpUser()) {
      policy = msalConfigForSignUpSubscribe;
    } else if (getSignUpGroupPlanFlow()) {
      policy = msalConfigForSignUpGroupProcessSubscribe("/plans");
    } else if (getSignUpGroupUser()) {
      policy = msalConfigForSignUpGroupProcess(redirectUrl);
    } else if (getViaMarketingURL()) {
      policy = msalConfigForSignUpMarketingProcess;
    } else if (getPaymentCookies()) {
      policy = msalConfigForPayment(getRedirect());
    } else if (getRootSignUp()) {
      policy = msalConfigForRootSignUp;
    } else if (getOfferSignUp()) {
      policy = msalConfigForOfferSignUp;
    } else if (getLoggedInViaShareableID()) {
      policy = msalConfigForStoryShareableSignInup("signUp");
    } else {
      policy = msalConfigForSignUp;
    }
  }

  if (userFlow === "signInUp") {
    if (getSignUpGroupUser()) {
      policy = msalConfigForSignInUpGroupProcess(redirectUrl);
    } else if (getViaMarketingURL()) {
      policy = msalConfigForSignInUpMarketingProcess;
    } else if (getOfferSignUp()) {
      policy = msalConfigForOfferSignInUp;
    } else if (getLoggedInViaShareableID()) {
      policy = msalConfigForStoryShareableSignInup("signIn");
    }
  }

  if (userFlow === "signInUpStories") {
    policy = msalConfigForSignInUpStoriesProcess;
  }

  if (sessionStorage.getItem("storyPreviewId")) {
    policy.auth.redirectUri = "/verify-story-permission";
    policy.auth.navigateToLoginRequestUrl = false;
  }
  return new PublicClientApplication(policy);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  }
});

const persister = createSyncStoragePersister({
  storage: window.localStorage
});

const root = createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <GlobalLoader />
    <RecurlyProvider publicKey="ewr1-omqyXxj8ZA6fMuuOtSq6VU">
      <QueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <ThemeProvider theme={muiTheme}>
          <App instance={getMsalInstance()} />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </RecurlyProvider>
  </I18nextProvider>
);

reportWebVitals();
