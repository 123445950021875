/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HintAcceptData, HintRejectData, HintUpdateHintStatusData } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Hint extends HttpClient {
  /**
   * No description
   *
   * @tags Hint
   * @name HintUpdateHintStatus
   * @request POST:/api/Hint/{hintId}/updateHintStatus/{status}
   * @secure
   */
  hintUpdateHintStatus = (hintId: string, status: string, params: RequestParams = {}) =>
    this.request<HintUpdateHintStatusData, any>({
      path: `/api/Hint/${hintId}/updateHintStatus/${status}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Hint
   * @name HintAccept
   * @request POST:/api/Hint/{hintId}/accept
   * @secure
   */
  hintAccept = (hintId: string, params: RequestParams = {}) =>
    this.request<HintAcceptData, any>({
      path: `/api/Hint/${hintId}/accept`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Hint
   * @name HintReject
   * @request POST:/api/Hint/{hintId}/reject
   * @secure
   */
  hintReject = (hintId: string, params: RequestParams = {}) =>
    this.request<HintRejectData, any>({
      path: `/api/Hint/${hintId}/reject`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
