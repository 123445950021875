/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AlexandersErrorData,
  AlexandersErrorPayload,
  AlexandersPrintingData,
  AlexandersPrintingPayload,
  AlexandersShippedData,
  AlexandersShippedPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Alexanders extends HttpClient {
  /**
   * No description
   *
   * @tags Alexanders
   * @name AlexandersPrinting
   * @request PUT:/api/Alexanders/printing/{orderId}
   * @secure
   */
  alexandersPrinting = (
    orderId: string,
    data: AlexandersPrintingPayload,
    params: RequestParams = {}
  ) =>
    this.request<AlexandersPrintingData, any>({
      path: `/api/Alexanders/printing/${orderId}`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Alexanders
   * @name AlexandersShipped
   * @request PUT:/api/Alexanders/shipped/{orderId}
   * @secure
   */
  alexandersShipped = (
    orderId: string,
    data: AlexandersShippedPayload,
    params: RequestParams = {}
  ) =>
    this.request<AlexandersShippedData, any>({
      path: `/api/Alexanders/shipped/${orderId}`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Alexanders
   * @name AlexandersError
   * @request PUT:/api/Alexanders/error/{orderId}
   * @secure
   */
  alexandersError = (orderId: string, data: AlexandersErrorPayload, params: RequestParams = {}) =>
    this.request<AlexandersErrorData, any>({
      path: `/api/Alexanders/error/${orderId}`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    });
}
