import "./index.css";

import { ButtonHTMLAttributes, CSSProperties, useState } from "react";

import Icon, { IconColor, IconSize } from "../Icon";
import Loader from "../Loader";
import Translator from "../Translator";

export type ButtonColor =
  | "default"
  | "default-dark"
  | "primary"
  | "primary-inverted"
  | "secondary"
  | "danger"
  | "success"
  | "skyblue"
  | "skyblue-1"
  | "skyblue-7"
  | "white"
  | "link"
  | "link-white"
  | "link-danger"
  | "link-danger-white"
  | "danger-gray-2"
  | "red"
  | "red-dark"
  | "stepper"
  | "purple-5"
  | "purple-6"
  | "purple-7"
  | "skyblueInverted"
  | "gray-2"
  | "gray-3"
  | "gray-outline-3"
  | "btn-white"
  | "link-blue-5"
  | "link-danger-dark"
  | "purpleInverted"
  | "maroon"
  | "gray-1"
  | "gray-6"
  | "gray-7"
  | "default-light";

interface Props {
  type?: ButtonColor;
  title?: string;
  size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
  icon?: string | boolean;
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tkey?: string;
  extraClass?: string;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  _textColor?: string;
  fontWeight?: string;
  fontSize?: string;
  fontColor?: string;
  iconSize?: IconSize;
  iconSwap?: string;
  btnJustify?: string;
  numberedBadge?: string;
  activeClass?: boolean;
  loading?: boolean;
  width?: "full" | "";
  textWrap?: CSSProperties["textWrap"]; // Our version of Tailwind doesn't have text-wrap classes.
  additionalStyles?: CSSProperties;
  [x: string]: any;
}

const Button = ({
  type = "primary",
  title = "Learn more",
  size = "medium",
  width,
  icon = false,
  disabled = false,
  handleClick = undefined,
  loading,
  tkey = "",
  buttonType,
  _textColor,
  fontWeight,
  fontSize,
  fontColor,
  iconSize,
  iconSwap,
  btnJustify,
  numberedBadge,
  activeClass,
  extraClass = "",
  ...props
}: Props) => {
  const getBtnType = () => {
    switch (type) {
      case "default":
        return "btn-default";
      case "default-dark":
        return "btn-default-dark";
      case "primary":
        return "btn-primary";
      case "primary-inverted":
        return "btn-primary-inverted";
      case "secondary":
        return "btn-secondary";
      case "danger":
        return "btn-danger";
      case "success":
        return "btn-success";
      case "red":
        return "btn-red";
      case "red-dark":
        return "btn-red-dark";
      case "purple-5":
        return "btn-purple-5";
      case "purple-6":
        return "btn-purple-6";
      case "purple-7":
        return "btn-purple-7";
      case "skyblue":
        return "btn-skyblue";
      case "skyblue-1":
        return "btn-skyblue-1";
      case "skyblue-7":
        return "btn-skyblue-7";
      case "white":
        return "btn-white";
      case "link-white":
        return "link-white";
      case "link":
        return "link";
      case "link-blue-5":
        return "link-blue-5";
      case "link-danger":
        return "link-danger";
      case "link-danger-white":
        return "link-danger-white";
      case "link-danger-dark":
        return "link-danger-dark";
      case "danger-gray-2":
        return "btn-danger-gray-2";
      case "skyblueInverted":
        return "btn-skyblue-inverted";
      case "purpleInverted":
        return "btn-purple-inverted";
      case "maroon":
        return "btn-maroon";
      case "stepper":
        return "btn-stepper";
      case "gray-2":
        return "btn-gray-2";
      case "gray-1":
        return "btn-gray-1";
      case "gray-3":
        return "btn-gray-3";
      case "gray-6":
        return "btn-gray-6";
      case "gray-outline-3":
        return "btn-gray-outline-3";
      case "default-light":
        return "btn-default-light";

      default:
        return "";
    }
  };
  const btnType = getBtnType();

  const getBtnSize = () => {
    switch (size) {
      case "small":
        return "btn-small";
      case "medium":
        return "btn-medium";
      case "large":
        return "btn-large";
      case "xlarge":
        return "btn-xlarge typo-font-bold";
      case "xxlarge":
        return "btn-xxlarge;";
      default:
        return "";
    }
  };
  const btnSize = getBtnSize();

  const getBtnWidth = () => {
    return width === "full" ? "w-full" : "";
  };
  const btnWidth = getBtnWidth();

  const btnDisable = disabled || loading ? "btn-disable" : "";

  const getlocalIconColor = () => {
    const lighterColors = [
      "default",
      "default-light",
      "white",
      "primary-inverted",
      "skyblue",
      "skyblue-1",
      "skyblue-7",
      "btn-white",
      "gray-1",
      "gray-2",
      "gray-3"
    ];
    let iconColor = "" as IconColor;
    switch (type) {
      case "default":
        iconColor = "secondary";
        break;
      case "stepper":
        iconColor = "default";
        break;
      default:
        iconColor = "white"; // Default icon color for other cases
    }
    if (type !== "stepper" && lighterColors.includes(type)) {
      iconColor = "gray-7"; // Set icon color to gray-7 for lighter button colors
    }
    return iconColor;
  };
  const localIconColor = getlocalIconColor();

  const [iconColor, setIconColor] = useState<IconColor>(localIconColor);

  const handleMouseEnter = () => {
    if (type === "stepper") setIconColor("primary");
  };

  const handleMouseLeave = () => {
    if (type === "stepper") setIconColor(localIconColor);
  };

  const getTitle = (tkeyin?: string) => {
    return tkeyin !== "" ? <Translator tkey={tkeyin} /> : title;
  };

  const getFontWeight = () => {
    switch (fontWeight) {
      case "regular":
        return "typo-font-regular";
      case "light":
        return "typo-font-light";
      case "medium":
        return "typo-font-medium";
      case "bold":
        return "typo-font-bold";
      case "lyon-regular":
        return "lyon-font-regular";
      case "lyon-medium":
        return "lyon-font-medium";
      default:
        return "typo-font-medium";
    }
  };
  const getFontSize = () => {
    switch (fontSize) {
      case "xs":
        return "text-xs";
      case "sm":
        return "text-sm";
      case "base":
        return "text-base";
      case "lg":
        return "text-lg";
      case "xl":
        return "text-xl";
      case "2xl":
        return "text-2xl";
      default:
        return "";
    }
  };
  const getFontColor = () => {
    switch (fontColor) {
      case "blue-4":
        return "text-blue-4";
      case "blue-5":
        return "text-blue-5";
      case "skyblue-6":
        return "text-skyblue-6";
      case "skyblue-7":
        return "text-skyblue-7";
      case "gray-5":
        return "text-gray-5";
      case "gray-6":
        return "text-gray-6";
      case "gray-7":
        return "text-gray-7";
      case "black":
        return "text-black";
      default:
        return "text-current";
    }
  };
  const getBtnJustify = () => {
    switch (btnJustify) {
      case "start":
        return "justify-start";
      case "center":
        return "justify-center";
      case "end":
        return "justify-end";
      default:
        return "";
    }
  };

  const getIconSwap = () => {
    return iconSwap === "swap" ? "icon-swap" : "";
  };

  const getActiveClass = () => {
    return activeClass ? "active" : "";
  };

  return (
    <button
      disabled={disabled || loading}
      style={{ textWrap: props.textWrap, ...props.additionalStyles }}
      type={buttonType ?? "button"}
      className={`btn ${btnType} ${btnSize} ${btnWidth} ${iconSize} ${getBtnJustify()} ${btnDisable} ${getActiveClass()} ${extraClass}`}
      onClick={!disabled ? handleClick : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {icon ? (
        <div className={`flex items-center swapper  ${getFontColor()} ${getIconSwap()}`}>
          <Icon type={icon as any} color={iconColor} size={iconSize} />
          <div
            className={`ml-2 ${
              !fontWeight ? "typo-font-light" : ""
            } ml-swapper ${getFontWeight()} ${getFontSize()} ${getFontColor()} ${extraClass}`}
          >
            {" "}
            {getTitle(tkey)}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full">
          {loading && (
            <span className="mr-2">
              <Loader spinner={true} color="default" size={12} />
            </span>
          )}
          <span
            className={`${getFontWeight()} ${getFontSize()} ${getFontColor()} ${
              numberedBadge && " flex items-center"
            }`}
          >
            {getTitle(tkey)}{" "}
            {numberedBadge && <span className="ml-1 hinttab-tag">{numberedBadge}</span>}
          </span>
        </div>
      )}
    </button>
  );
};

export default Button;
