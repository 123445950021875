/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContentCatalog2Data,
  ContentCatalog2Params,
  ContentCatalogCategoriesData,
  ContentCatalogCategoryTagsByCategoryData,
  ContentCatalogCategoryTagsData,
  ContentCatalogCompositeFormDataFromCategoryData,
  ContentCatalogCompositeFormDataFromTagData,
  ContentCatalogData,
  ContentCatalogEventData,
  ContentCatalogGetByUrlData,
  ContentCatalogGetByUrlParams,
  ContentCatalogReloadCollectionsData,
  ContentCatalogReloadNegativeQueryWeightData,
  ContentCatalogReloadQueryWeightData,
  ContentCatalogReloadSubscriptionsData
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class ContentCatalog extends HttpClient {
  /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalog
   * @request GET:/api/ContentCatalog/{collectionId}
   * @secure
   */
  contentCatalog = (collectionId: string, params: RequestParams = {}) =>
    this.request<ContentCatalogData, any>({
      path: `/api/ContentCatalog/${collectionId}`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalog2
   * @request GET:/api/ContentCatalog
   * @originalName contentCatalog
   * @duplicate
   * @secure
   */
  contentCatalog2 = (query: ContentCatalog2Params, params: RequestParams = {}) =>
    this.request<ContentCatalog2Data, any>({
      path: `/api/ContentCatalog`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogCompositeFormDataFromCategory
   * @request GET:/api/ContentCatalog/{collectionCategory}/composite-form-data-from-category
   * @secure
   */
  contentCatalogCompositeFormDataFromCategory = (
    collectionCategory: string,
    params: RequestParams = {}
  ) =>
    this.request<ContentCatalogCompositeFormDataFromCategoryData, any>({
      path: `/api/ContentCatalog/${collectionCategory}/composite-form-data-from-category`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogCompositeFormDataFromTag
   * @request GET:/api/ContentCatalog/{categoryTag}/composite-form-data-from-tag
   * @secure
   */
  contentCatalogCompositeFormDataFromTag = (categoryTag: string, params: RequestParams = {}) =>
    this.request<ContentCatalogCompositeFormDataFromTagData, any>({
      path: `/api/ContentCatalog/${categoryTag}/composite-form-data-from-tag`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogEvent
   * @request GET:/api/ContentCatalog/event
   * @secure
   */
  contentCatalogEvent = (params: RequestParams = {}) =>
    this.request<ContentCatalogEventData, any>({
      path: `/api/ContentCatalog/event`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogCategories
   * @request GET:/api/ContentCatalog/categories
   * @secure
   */
  contentCatalogCategories = (params: RequestParams = {}) =>
    this.request<ContentCatalogCategoriesData, any>({
      path: `/api/ContentCatalog/categories`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogCategoryTags
   * @request GET:/api/ContentCatalog/category-tags
   * @secure
   */
  contentCatalogCategoryTags = (params: RequestParams = {}) =>
    this.request<ContentCatalogCategoryTagsData, any>({
      path: `/api/ContentCatalog/category-tags`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogCategoryTagsByCategory
   * @request GET:/api/ContentCatalog/{category}/category-tags-by-category
   * @secure
   */
  contentCatalogCategoryTagsByCategory = (category: string, params: RequestParams = {}) =>
    this.request<ContentCatalogCategoryTagsByCategoryData, any>({
      path: `/api/ContentCatalog/${category}/category-tags-by-category`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogReloadCollections
   * @request GET:/api/ContentCatalog/reloadCollections
   * @secure
   */
  contentCatalogReloadCollections = (params: RequestParams = {}) =>
    this.request<ContentCatalogReloadCollectionsData, any>({
      path: `/api/ContentCatalog/reloadCollections`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogReloadSubscriptions
   * @request GET:/api/ContentCatalog/reloadSubscriptions
   * @secure
   */
  contentCatalogReloadSubscriptions = (params: RequestParams = {}) =>
    this.request<ContentCatalogReloadSubscriptionsData, any>({
      path: `/api/ContentCatalog/reloadSubscriptions`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogReloadQueryWeight
   * @request GET:/api/ContentCatalog/reloadQueryWeight
   * @secure
   */
  contentCatalogReloadQueryWeight = (params: RequestParams = {}) =>
    this.request<ContentCatalogReloadQueryWeightData, any>({
      path: `/api/ContentCatalog/reloadQueryWeight`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogReloadNegativeQueryWeight
   * @request GET:/api/ContentCatalog/reloadNegativeQueryWeight
   * @secure
   */
  contentCatalogReloadNegativeQueryWeight = (params: RequestParams = {}) =>
    this.request<ContentCatalogReloadNegativeQueryWeightData, any>({
      path: `/api/ContentCatalog/reloadNegativeQueryWeight`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags ContentCatalog
   * @name ContentCatalogGetByUrl
   * @request GET:/api/ContentCatalog/get-by-url
   * @secure
   */
  contentCatalogGetByUrl = (query: ContentCatalogGetByUrlParams, params: RequestParams = {}) =>
    this.request<ContentCatalogGetByUrlData, any>({
      path: `/api/ContentCatalog/get-by-url`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    });
}
