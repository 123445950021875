/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { NewspaperData } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Newspaper extends HttpClient {
  /**
   * No description
   *
   * @tags Newspaper
   * @name Newspaper
   * @request GET:/api/Newspaper/{pubTitle}/{pubDate}/{pageNumber}
   * @secure
   */
  newspaper = (pubTitle: string, pubDate: string, pageNumber: number, params: RequestParams = {}) =>
    this.request<NewspaperData, any>({
      path: `/api/Newspaper/${pubTitle}/${pubDate}/${pageNumber}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
