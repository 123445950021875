
import { actionCreator } from '../utils';
import * as CONSTANTS from '../constants/actionTypes';
import { AppDispatch } from 'redux/store';
import { AppThunk } from './payments';

export type SnackbarType = 'success' | 'error' | 'warning' | 'info' | 'notification';

export interface SnackbarMessage {
  key: number;
  content: string;
  type: SnackbarType;
  canClose: boolean;
  url?: string;
  showIcon: boolean;
  cta?: Cta;
}

export interface Cta {
  label: string;
  action: () => void;
}

export function addMessage(
  message: string,
  type: SnackbarType = 'success',
  meta?: { url?: string; cta?: Cta; showIcon: boolean }
): AppThunk<Promise<void>> {
  return (dispatch) => {
    const options = { canClose: true, cta: null, ...meta };
    const snackbarMessage: SnackbarMessage = {
      key: new Date().getTime(),
      content: message,
      type: type,
      canClose: options.canClose,
      showIcon: !!options.showIcon,
      ...(options.url ? { url: options.url } : {}),
      ...(options.cta?.action ? { cta: options.cta } : {})
    };
    dispatch(actionCreator<SnackbarMessage>(CONSTANTS.ADDTMESSAGE, snackbarMessage));

    // Return a resolved promise to indicate completion
    return Promise.resolve();
  };
}


export const removeMessage = (messageId: any) => {
  return (dispatch: AppDispatch) => {
    dispatch(actionCreator(CONSTANTS.REMOVETMESSAGE, messageId));
  };
};
