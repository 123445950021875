/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminCorrectiveActionsFixEmptyLocationStreamsData,
  AdminCorrectiveActionsReplayMissedEventsPostOrleans8Data
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class CorrectiveActions extends HttpClient {
  /**
   * No description
   *
   * @tags CorrectiveActions
   * @name AdminCorrectiveActionsReplayMissedEventsPostOrleans8
   * @request GET:/api/admin/CorrectiveActions/replayMissedEventsPostOrleans8
   * @secure
   */
  adminCorrectiveActionsReplayMissedEventsPostOrleans8 = (params: RequestParams = {}) =>
    this.request<AdminCorrectiveActionsReplayMissedEventsPostOrleans8Data, any>({
      path: `/api/admin/CorrectiveActions/replayMissedEventsPostOrleans8`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags CorrectiveActions
   * @name AdminCorrectiveActionsFixEmptyLocationStreams
   * @request GET:/api/admin/CorrectiveActions/fix-empty-location-streams
   * @secure
   */
  adminCorrectiveActionsFixEmptyLocationStreams = (params: RequestParams = {}) =>
    this.request<AdminCorrectiveActionsFixEmptyLocationStreamsData, any>({
      path: `/api/admin/CorrectiveActions/fix-empty-location-streams`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
