/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GiftOrderOrderData, GiftOrderOrderPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class GiftOrder extends HttpClient {
  /**
   * No description
   *
   * @tags GiftOrder
   * @name GiftOrderOrder
   * @request POST:/api/GiftOrder/order
   * @secure
   */
  giftOrderOrder = (data: GiftOrderOrderPayload, params: RequestParams = {}) =>
    this.request<GiftOrderOrderData, any>({
      path: `/api/GiftOrder/order`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    });
}
