/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  RecordCorrections2Data,
  RecordCorrections2Payload,
  RecordCorrectionsAddRecordCorrectionsData,
  RecordCorrectionsAddRecordCorrectionsPayload,
  RecordCorrectionsData,
  RecordCorrectionsDeleteRecordCorrectionsData,
  RecordCorrectionsDeleteRecordCorrectionsPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class RecordCorrections extends HttpClient {
  /**
   * No description
   *
   * @tags RecordCorrections
   * @name RecordCorrectionsAddRecordCorrections
   * @request POST:/api/RecordCorrections/addRecordCorrections
   * @secure
   */
  recordCorrectionsAddRecordCorrections = (
    data: RecordCorrectionsAddRecordCorrectionsPayload,
    params: RequestParams = {}
  ) =>
    this.request<RecordCorrectionsAddRecordCorrectionsData, any>({
      path: `/api/RecordCorrections/addRecordCorrections`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags RecordCorrections
   * @name RecordCorrectionsDeleteRecordCorrections
   * @request POST:/api/RecordCorrections/deleteRecordCorrections
   * @secure
   */
  recordCorrectionsDeleteRecordCorrections = (
    data: RecordCorrectionsDeleteRecordCorrectionsPayload,
    params: RequestParams = {}
  ) =>
    this.request<RecordCorrectionsDeleteRecordCorrectionsData, any>({
      path: `/api/RecordCorrections/deleteRecordCorrections`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags RecordCorrections
   * @name RecordCorrections
   * @request GET:/api/RecordCorrections/{recordId}
   * @secure
   */
  recordCorrections = (recordId: string, params: RequestParams = {}) =>
    this.request<RecordCorrectionsData, any>({
      path: `/api/RecordCorrections/${recordId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags RecordCorrections
   * @name RecordCorrections2
   * @request POST:/api/RecordCorrections
   * @originalName recordCorrections
   * @duplicate
   * @secure
   */
  recordCorrections2 = (data: RecordCorrections2Payload, params: RequestParams = {}) =>
    this.request<RecordCorrections2Data, any>({
      path: `/api/RecordCorrections`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    });
}
