/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetPetData,
  PetDeletepetbackgroundimageData,
  PetDeletepetprofileimageData,
  PetPetStoriesData,
  PetPetStoriesParams,
  PetPetmedialistData,
  PetPetmedialistParams,
  PetPetrelationshipsData,
  PetPetrelationshipsParams,
  PetPetstoriescountbycategoriesData,
  V2PetPetMediaListData,
  V2PetPetMediaListParams
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Pet extends HttpClient {
  /**
   * No description
   *
   * @tags Pet
   * @name PetDeletepetprofileimage
   * @request DELETE:/api/Pet/deletepetprofileimage/{petId}
   * @secure
   */
  petDeletepetprofileimage = (petId: string, params: RequestParams = {}) =>
    this.request<PetDeletepetprofileimageData, any>({
      path: `/api/Pet/deletepetprofileimage/${petId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name PetDeletepetbackgroundimage
   * @request DELETE:/api/Pet/deletepetbackgroundimage/{petId}
   * @secure
   */
  petDeletepetbackgroundimage = (petId: string, params: RequestParams = {}) =>
    this.request<PetDeletepetbackgroundimageData, any>({
      path: `/api/Pet/deletepetbackgroundimage/${petId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name GetPet
   * @request GET:/api/Pet/{petId}
   * @secure
   */
  getPet = (petId: string, params: RequestParams = {}) =>
    this.request<GetPetData, any>({
      path: `/api/Pet/${petId}`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name PetPetrelationships
   * @request GET:/api/Pet/petrelationships
   * @secure
   */
  petPetrelationships = (query: PetPetrelationshipsParams, params: RequestParams = {}) =>
    this.request<PetPetrelationshipsData, any>({
      path: `/api/Pet/petrelationships`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name PetPetmedialist
   * @request GET:/api/Pet/{pageNumber}/{pageSize}/petmedialist
   * @secure
   */
  petPetmedialist = (
    { pageNumber, pageSize, ...query }: PetPetmedialistParams,
    params: RequestParams = {}
  ) =>
    this.request<PetPetmedialistData, any>({
      path: `/api/Pet/${pageNumber}/${pageSize}/petmedialist`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name PetPetStories
   * @request GET:/api/Pet/{petId}/{pageNumber}/{pageSize}/PetStories
   * @secure
   */
  petPetStories = (
    { petId, pageNumber, pageSize, ...query }: PetPetStoriesParams,
    params: RequestParams = {}
  ) =>
    this.request<PetPetStoriesData, any>({
      path: `/api/Pet/${petId}/${pageNumber}/${pageSize}/PetStories`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name PetPetstoriescountbycategories
   * @request GET:/api/Pet/{petId}/petstoriescountbycategories
   * @secure
   */
  petPetstoriescountbycategories = (petId: string, params: RequestParams = {}) =>
    this.request<PetPetstoriescountbycategoriesData, any>({
      path: `/api/Pet/${petId}/petstoriescountbycategories`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Pet
   * @name V2PetPetMediaList
   * @request GET:/api/v2/Pet/{pageNumber}/pet-media-list
   * @secure
   */
  v2PetPetMediaList = (
    { pageNumber, ...query }: V2PetPetMediaListParams,
    params: RequestParams = {}
  ) =>
    this.request<V2PetPetMediaListData, any>({
      path: `/api/v2/Pet/${pageNumber}/pet-media-list`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
