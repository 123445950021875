/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SearchFormsAvailableFamilyRelationshipsByCategoryData,
  SearchFormsAvailableFamilyRelationshipsByCategoryTagData,
  SearchFormsAvailableFamilyRelationshipsData,
  SearchFormsCategoryDropdownsData,
  SearchFormsCategoryTagDropdownsData,
  SearchFormsCollectionPlanTypeData,
  SearchFormsCollectionPlanTypeParams,
  SearchFormsDropdownsData,
  SearchFormsDynamicSearchByCategoryData,
  SearchFormsDynamicSearchByCategoryPayload,
  SearchFormsDynamicSearchByCategoryTagData,
  SearchFormsDynamicSearchByCategoryTagPayload,
  SearchFormsDynamicSearchByCollectionIdData,
  SearchFormsDynamicSearchByCollectionIdPayload,
  SearchFormsDynamicSearchQueryByCategoryData,
  SearchFormsDynamicSearchQueryByCategoryPayload,
  SearchFormsDynamicSearchQueryByCategoryTagData,
  SearchFormsDynamicSearchQueryByCategoryTagPayload,
  SearchFormsDynamicSearchQueryByCollectionIdData,
  SearchFormsDynamicSearchQueryByCollectionIdPayload,
  SearchFormsReloadDataData,
  SearchFormsSearchableDropdownsByCategoryData,
  SearchFormsSearchableDropdownsByCategoryParams,
  SearchFormsSearchableDropdownsByCategoryTagData,
  SearchFormsSearchableDropdownsByCategoryTagParams,
  SearchFormsSearchableDropdownsData,
  SearchFormsSearchableDropdownsParams,
  SearchFormsUniversalSearchData,
  SearchFormsUniversalSearchPayload,
  SearchFormsUniversalSearchQueryData,
  SearchFormsUniversalSearchQueryPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class SearchForm extends HttpClient {
  /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDropdowns
   * @request GET:/api/search/forms/{collectionId}/dropdowns
   * @secure
   */
  searchFormsDropdowns = (collectionId: string, params: RequestParams = {}) =>
    this.request<SearchFormsDropdownsData, any>({
      path: `/api/search/forms/${collectionId}/dropdowns`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsReloadData
   * @request GET:/api/search/forms/reloadData
   * @secure
   */
  searchFormsReloadData = (params: RequestParams = {}) =>
    this.request<SearchFormsReloadDataData, any>({
      path: `/api/search/forms/reloadData`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsCategoryDropdowns
   * @request GET:/api/search/forms/{category}/categoryDropdowns
   * @secure
   */
  searchFormsCategoryDropdowns = (category: string, params: RequestParams = {}) =>
    this.request<SearchFormsCategoryDropdownsData, any>({
      path: `/api/search/forms/${category}/categoryDropdowns`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsCategoryTagDropdowns
   * @request GET:/api/search/forms/{categoryTag}/category-tag-dropdowns
   * @secure
   */
  searchFormsCategoryTagDropdowns = (categoryTag: string, params: RequestParams = {}) =>
    this.request<SearchFormsCategoryTagDropdownsData, any>({
      path: `/api/search/forms/${categoryTag}/category-tag-dropdowns`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsSearchableDropdowns
   * @request GET:/api/search/forms/{collectionId}/searchable-dropdowns
   * @secure
   */
  searchFormsSearchableDropdowns = (
    { collectionId, ...query }: SearchFormsSearchableDropdownsParams,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsSearchableDropdownsData, any>({
      path: `/api/search/forms/${collectionId}/searchable-dropdowns`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsSearchableDropdownsByCategory
   * @request GET:/api/search/forms/{category}/searchable-dropdowns-by-category
   * @secure
   */
  searchFormsSearchableDropdownsByCategory = (
    { category, ...query }: SearchFormsSearchableDropdownsByCategoryParams,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsSearchableDropdownsByCategoryData, any>({
      path: `/api/search/forms/${category}/searchable-dropdowns-by-category`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsSearchableDropdownsByCategoryTag
   * @request GET:/api/search/forms/{categoryTag}/searchable-dropdowns-by-category-tag
   * @secure
   */
  searchFormsSearchableDropdownsByCategoryTag = (
    { categoryTag, ...query }: SearchFormsSearchableDropdownsByCategoryTagParams,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsSearchableDropdownsByCategoryTagData, any>({
      path: `/api/search/forms/${categoryTag}/searchable-dropdowns-by-category-tag`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsAvailableFamilyRelationships
   * @request GET:/api/search/forms/{collectionId}/available-family-relationships
   * @secure
   */
  searchFormsAvailableFamilyRelationships = (collectionId: string, params: RequestParams = {}) =>
    this.request<SearchFormsAvailableFamilyRelationshipsData, any>({
      path: `/api/search/forms/${collectionId}/available-family-relationships`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsAvailableFamilyRelationshipsByCategory
   * @request GET:/api/search/forms/{category}/available-family-relationships-by-category
   * @secure
   */
  searchFormsAvailableFamilyRelationshipsByCategory = (
    category: string,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsAvailableFamilyRelationshipsByCategoryData, any>({
      path: `/api/search/forms/${category}/available-family-relationships-by-category`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsAvailableFamilyRelationshipsByCategoryTag
   * @request GET:/api/search/forms/{categoryTag}/available-family-relationships-by-category-tag
   * @secure
   */
  searchFormsAvailableFamilyRelationshipsByCategoryTag = (
    categoryTag: string,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsAvailableFamilyRelationshipsByCategoryTagData, any>({
      path: `/api/search/forms/${categoryTag}/available-family-relationships-by-category-tag`,
      method: "GET",
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsUniversalSearch
   * @request POST:/api/search/forms/universal-search
   * @secure
   */
  searchFormsUniversalSearch = (
    data: SearchFormsUniversalSearchPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsUniversalSearchData, any>({
      path: `/api/search/forms/universal-search`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsUniversalSearchQuery
   * @request POST:/api/search/forms/universal-search-query
   * @secure
   */
  searchFormsUniversalSearchQuery = (
    data: SearchFormsUniversalSearchQueryPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsUniversalSearchQueryData, any>({
      path: `/api/search/forms/universal-search-query`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDynamicSearchByCollectionId
   * @request POST:/api/search/forms/dynamic-search-by-collection-id
   * @secure
   */
  searchFormsDynamicSearchByCollectionId = (
    data: SearchFormsDynamicSearchByCollectionIdPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsDynamicSearchByCollectionIdData, any>({
      path: `/api/search/forms/dynamic-search-by-collection-id`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDynamicSearchQueryByCollectionId
   * @request POST:/api/search/forms/dynamic-search-query-by-collection-id
   * @secure
   */
  searchFormsDynamicSearchQueryByCollectionId = (
    data: SearchFormsDynamicSearchQueryByCollectionIdPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsDynamicSearchQueryByCollectionIdData, any>({
      path: `/api/search/forms/dynamic-search-query-by-collection-id`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsCollectionPlanType
   * @request GET:/api/search/forms/collectionPlanType
   * @secure
   */
  searchFormsCollectionPlanType = (
    query: SearchFormsCollectionPlanTypeParams,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsCollectionPlanTypeData, any>({
      path: `/api/search/forms/collectionPlanType`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: true,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDynamicSearchQueryByCategory
   * @request POST:/api/search/forms/dynamic-search-query-by-category
   * @secure
   */
  searchFormsDynamicSearchQueryByCategory = (
    data: SearchFormsDynamicSearchQueryByCategoryPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsDynamicSearchQueryByCategoryData, any>({
      path: `/api/search/forms/dynamic-search-query-by-category`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDynamicSearchByCategory
   * @request POST:/api/search/forms/dynamic-search-by-category
   * @secure
   */
  searchFormsDynamicSearchByCategory = (
    data: SearchFormsDynamicSearchByCategoryPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsDynamicSearchByCategoryData, any>({
      path: `/api/search/forms/dynamic-search-by-category`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDynamicSearchQueryByCategoryTag
   * @request POST:/api/search/forms/dynamic-search-query-by-category-tag
   * @secure
   */
  searchFormsDynamicSearchQueryByCategoryTag = (
    data: SearchFormsDynamicSearchQueryByCategoryTagPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsDynamicSearchQueryByCategoryTagData, any>({
      path: `/api/search/forms/dynamic-search-query-by-category-tag`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags SearchForm
   * @name SearchFormsDynamicSearchByCategoryTag
   * @request POST:/api/search/forms/dynamic-search-by-category-tag
   * @secure
   */
  searchFormsDynamicSearchByCategoryTag = (
    data: SearchFormsDynamicSearchByCategoryTagPayload,
    params: RequestParams = {}
  ) =>
    this.request<SearchFormsDynamicSearchByCategoryTagData, any>({
      path: `/api/search/forms/dynamic-search-by-category-tag`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    });
}
