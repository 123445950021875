/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminPrintOrderPrintOrderData,
  AdminPrintOrderPrintOrderPayload,
  PrintOrderCancelorderData,
  PrintOrderCreateorderData,
  PrintOrderCreateorderPayload,
  PrintOrderOrderAdditionalCopiesData,
  PrintOrderOrderAdditionalCopiesPayload,
  PrintOrderPrintorderData,
  PrintOrderPrintorderPayload,
  PrintOrderPrintorderlistData,
  PrintOrderShippingaddressData,
  PrintOrderShippingaddressParams,
  PrintOrderShippingaddressPayload,
  PrintOrderShippingmethodData,
  PrintOrderShippingmethodParams,
  PrintOrderSubmitForPrintingData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PrintOrder extends HttpClient {
  /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderPrintorder
   * @request POST:/api/PrintOrder/printorder
   * @secure
   */
  printOrderPrintorder = (data: PrintOrderPrintorderPayload, params: RequestParams = {}) =>
    this.request<PrintOrderPrintorderData, any>({
      path: `/api/PrintOrder/printorder`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderCreateorder
   * @request POST:/api/PrintOrder/createorder
   * @secure
   */
  printOrderCreateorder = (data: PrintOrderCreateorderPayload, params: RequestParams = {}) =>
    this.request<PrintOrderCreateorderData, any>({
      path: `/api/PrintOrder/createorder`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderSubmitForPrinting
   * @request POST:/api/PrintOrder/submit-for-printing
   * @secure
   */
  printOrderSubmitForPrinting = (params: RequestParams = {}) =>
    this.request<PrintOrderSubmitForPrintingData, any>({
      path: `/api/PrintOrder/submit-for-printing`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderCancelorder
   * @request DELETE:/api/PrintOrder/cancelorder/{orderId}
   * @secure
   */
  printOrderCancelorder = (orderId: string, params: RequestParams = {}) =>
    this.request<PrintOrderCancelorderData, any>({
      path: `/api/PrintOrder/cancelorder/${orderId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderShippingaddress
   * @request PUT:/api/PrintOrder/shippingaddress
   * @secure
   */
  printOrderShippingaddress = (
    query: PrintOrderShippingaddressParams,
    data: PrintOrderShippingaddressPayload,
    params: RequestParams = {}
  ) =>
    this.request<PrintOrderShippingaddressData, any>({
      path: `/api/PrintOrder/shippingaddress`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderShippingmethod
   * @request PUT:/api/PrintOrder/shippingmethod
   * @secure
   */
  printOrderShippingmethod = (query: PrintOrderShippingmethodParams, params: RequestParams = {}) =>
    this.request<PrintOrderShippingmethodData, any>({
      path: `/api/PrintOrder/shippingmethod`,
      method: "PUT",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderOrderAdditionalCopies
   * @request POST:/api/PrintOrder/order-additional-copies
   * @secure
   */
  printOrderOrderAdditionalCopies = (
    data: PrintOrderOrderAdditionalCopiesPayload,
    params: RequestParams = {}
  ) =>
    this.request<PrintOrderOrderAdditionalCopiesData, any>({
      path: `/api/PrintOrder/order-additional-copies`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name AdminPrintOrderPrintOrder
   * @request POST:/api/admin/PrintOrder/print-order/{userId}
   * @secure
   */
  adminPrintOrderPrintOrder = (
    userId: string,
    data: AdminPrintOrderPrintOrderPayload,
    params: RequestParams = {}
  ) =>
    this.request<AdminPrintOrderPrintOrderData, any>({
      path: `/api/admin/PrintOrder/print-order/${userId}`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags PrintOrder
   * @name PrintOrderPrintorderlist
   * @request GET:/api/PrintOrder/printorderlist
   * @secure
   */
  printOrderPrintorderlist = (params: RequestParams = {}) =>
    this.request<PrintOrderPrintorderlistData, any>({
      path: `/api/PrintOrder/printorderlist`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}
