import { REQUEST_HINT_GENERATION } from "redux/constants/actionTypes";
import {
  FAMILY_LOADING,
  GET_FAMILY,
  CLEAR_FAMILY,
  PERSON_LOADING,
  SAVE_PARENTS,
  ADD_PARENT,
  ADD_SIBLING,
  SAVE_PARENT,
  ADD_SPOUSE,
  SAVE_SPOUSE,
  SAVE_ERROR,
  ADD_FAMILY,
  CANCEL_MODAL,
  GET_EDIT_PERSON,
  UPDATE_FAMILY,
  FAMILY_ERROR,
  RENDER_STEPPER,
  RENDER_TREE,
  TREE_ERROR,
  AUTOCOMPLETE_TEST,
  AUTOCOMPLETE_ERROR,
  AUTOCOMPLETE_REQUEST,
  AUTOCOMPLETE_PAGINATION_ERROR,
  AUTOCOMPLETE_PAGINATION_TEST,
  AUTOCOMPLETE_PAGINATION_REQUEST,
  GET_TREES,
  IS_PRIVATE_TREE,
  CLEAR_IS_PRIVATE_TREE,
  TREES_LOADING,
  SAVE_SIBLING,
  ADD_CHILD,
  SAVE_CHILD,
  SAVE_PROFILE_IMAGE,
  IMAGE_LOADING,
  CLEAR_IMAGE,
  SET_MODAL_DATA,
  GET_PROFILE_IMAGE,
  FETCHING_IMAGE,
  SHOW_IMAGE,
  AUTOCOMPLETE_BIRTH_TEST,
  AUTOCOMPLETE_DEATH_TEST,
  AUTOCOMPLETE_BIRTH_PAGINATION_TEST,
  CLEAR_BIRTH_OPTIONS,
  CLEAR_DEATH_OPTIONS,
  CLEAR_RESIDENCE_OPTIONS,
  TREE_PERSON_SEARCH_OPTIONS,
  ADD_PARENT_VIA_PLACEHOLDER,
  SAVE_PARENT_VIA_PLACEHOLDER,
  REFETCH_PAGE_AFTER_UPLOAD,
  IMAGE_UNLOADING,
  SET_NEW_FAMILY_PAYLOAD,
  SET_HINTS_PAYLOAD,
  UPDATE_HINT_COUNT_ITEM,
  GET_EDITED_FAMILY,
  SAVE_HERO_IMAGE,
  GET_HERO_IMAGE,
  THUMBNAIL_UPLOAD_COMPLETE,
  ADD_RELATED_EVENT,
  SET_EVENT_MODAL_DATA,
  DIRECT_CHILDREN,
  CLEAR_EVENT_INFO,
  SAVING_MODAL,
  SAVED_MODAL,
  DELETE_PERSON_LOADING,
  DELETE_PERSON_LOADED,
  REFETCH_FAMILY_INFO,
  REFETCH_FAMILY_INFO_COMPLETE,
  SET_HOMEPERSON_MODAL,
  SET_HOMEPERSON,
  CLOSE_HOMEPERSON_MODAL,
  CLEAR_REFETCH_TREE_FLAG,
  DELETE_PET_LOADED,
  DELETE_PET_LOADING,
  DELETE_PET_PROFILE_IMAGE,
  DELETE_PET_BG_IMAGE,
  TREE_PROGRESS_PAYLOAD,
  REFETCH_TREE_PROGRESS,
  SHOW_TREE_CELEBRATORY_MODAL,
  CLOSE_TREE_PROGRESS_MODAL,
  NEW_NODE_HIGHTLIGHT,
  COLLECTIONID_HIGHEST_HINT,
  SELECTED_PARENT_SIDE,
  UPDATE_SELECTED_PARENT_SIDE,
  SHOW_ADD_GRANDPARENT_MODAL,
  CLOSE_ADD_GRANDPARENT_MODAL,
  UPDATE_HINT_COUNT_ITEMS,
  SET_MATCHES,
  GETRIGHTPANELDETAILS_SUCCESS,
  NOT_FOUND_ERROR
} from "../constants";

export const initialState = {
  loading: true,
  error: null,
  stepper: true,
  family: null,
  oldFamily: null,
  options: [],
  birthPlaceOptions: [],
  deathPlaceOptions: [],
  optionLoading: false,
  treePersonOptions: [],
  trees: [],
  treesloading: true,
  assigned: null,
  personLoading: false,
  editPerson: null,
  newPerson: null,
  parentAdded: false,
  spouseAdded: false,
  updatedPerson: false,
  siblingAdded: false,
  childAdded: false,
  parentAddedViaPlaceholder: false,
  addParents: null,
  profileImageAdded: false,
  heroImageAdded: false,
  imageLoading: false,
  imageFetching: false,
  parentsAdded: false,
  dropDownPayload: null,
  hasNextPage: true,
  isNextPageLoading: false,
  originalImage: null,
  showImageCropper: false,
  closeModalStatus: false,
  originalHeroImage: null,
  refetchTree: false,
  directChildren: null,
  savingModal: false,
  deletingPerson: false,
  refetchTreePostDeletetion: false,
  refetchFamily: false,
  homePersonModal: false,
  selectedHomePerson: null,
  privateTree: null,
  petProfileImageDeleted: false,
  petBGImageDeleted: false,
  treeProgressPayload: null,
  refetchTreePanel: false,
  showTreeCelebratoryModal: false,
  hintsCountPayload: [],
  newspaperMatchesCount: [],
  highlightNewNode: null,
  selectedParentSide: null,
  showAddGrandParentModal: null,
  restrictFirstHintHighlight: false,
  collectionIdOfHighestHint: null,
  notFoundError: false
};

const family = (state = initialState, action = {}) => {
  const { type, payload } = action;
  const mapping = {
    [RENDER_STEPPER]: {
      ...state,
      stepper: true,
    },
    [RENDER_TREE]: {
      ...state,
      stepper: false,
    },
    [FAMILY_LOADING]: {
      ...state,
      loading: true,
    },
    [PERSON_LOADING]: {
      ...state,
      personLoading: true,
    },
    [IMAGE_LOADING]: {
      ...state,
      imageLoading: true,
    },
    [IMAGE_UNLOADING]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
    },
    [FETCHING_IMAGE]: {
      ...state,
      imageFetching: true,
      showImageCropper: true,
    },
    [GET_EDIT_PERSON]: {
      ...state,
      personLoading: false,
      editPerson: payload,
      newPerson: null,
      restrictFirstHintHighlight: true,
    },
    [SET_NEW_FAMILY_PAYLOAD]: {
      ...state,
      family: payload,
      showImageCropper: false,
    },
    [SET_HINTS_PAYLOAD]: {
      ...state,
      hintsCountPayload: {
        ...state.hintsCountPayload,
        [payload?.familyId]: {
          ...state.hintsCountPayload?.[payload?.familyId],
          ...payload?.hints,
        },
      },
    },
    [SET_MATCHES]: {
      ...state,
      newspaperMatchesCount: payload?.matches,
    },
    [UPDATE_HINT_COUNT_ITEM]: {
      ...state,
      hintsCountPayload: {
        ...state.hintsCountPayload,
        [payload?.familyId]: {
          ...state.hintsCountPayload?.[payload?.familyId],
          [payload?.personAndHintCount?.personId]: payload?.personAndHintCount,
        },
      },
    },
    [UPDATE_HINT_COUNT_ITEMS]: {
      ...state,
      hintsCountPayload: {
        ...state.hintsCountPayload,
        [payload?.familyId]: {
          ...state.hintsCountPayload?.[payload?.familyId],
          ...payload?.items,
        },
      },
    },
    [GET_EDITED_FAMILY]: {
      ...state,
      updatedPerson: true,
      checkRefetchFamily: true,
    },
    [SET_MODAL_DATA]: {
      ...state,
      parentAdded: false,
      parentAddedViaPlaceholder: false,
      personLoading: false,
      editPerson: null,
      newPerson: payload,
      restrictFirstHintHighlight: true,
    },
    [SET_EVENT_MODAL_DATA]: {
      ...state,
      eventDetails: payload,
    },
    [ADD_PARENT]: {
      ...state,
      dropDownPayload: payload,
    },
    [ADD_SPOUSE]: {
      ...state,
      spouseAdded: false,
      personLoading: false,
      editPerson: null,
      dropDownPayload: payload,
    },
    [ADD_SIBLING]: {
      ...state,
      dropDownPayload: payload,
    },
    [ADD_CHILD]: {
      ...state,
      childAdded: false,
      personLoading: false,
      editPerson: null,
      dropDownPayload: payload,
    },
    [SAVE_PARENT]: {
      ...state,
      parentAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_SPOUSE]: {
      ...state,
      spouseAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_SIBLING]: {
      ...state,
      siblingAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_CHILD]: {
      ...state,
      childAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_ERROR]: {
      ...state,
      error: payload,
      loading: false,
      closeModalStatus: true,
      refetchTree: true,
      savingModal: false,
    },
    [ADD_FAMILY]: {
      ...state,
      personLoading: false,
      editPerson: null,
      newPerson: payload,
    },
    [CANCEL_MODAL]: {
      ...state,
      personLoading: false,
      editPerson: null,
      newPerson: null,
      updatedPerson: false,
      parentAdded: false,
      parentAddedViaPlaceholder: false,
      spouseAdded: false,
      siblingAdded: false,
      childAdded: false,
      profileImageAdded: false,
      dropDownPayload: null,
      birthPlaceOptions: [],
      deathPlaceOptions: [],
      closeModalStatus: false,
      restrictFirstHintHighlight: false,
    },
    [GET_FAMILY]: {
      ...state,
      stepper: false,
      family: payload,
      refetchTreePanel: true,
      oldFamily: payload,
      newPerson: null,
      loading: false,
      updatedPerson: false,
      parentAdded: false,
      parentAddedViaPlaceholder: false,
      spouseAdded: false,
      siblingAdded: false,
      childAdded: false,
      imageLoading: false,
      profileImageAdded: false,
      error: null,
      parentsAdded: false,
      checkRefetchFamily: false,
      closeModalStatus: false,
      refetchTree: false,
      refetchTreePostDeletetion: false,
      pendingNotificationSnackbar: null,
    },
    [UPDATE_FAMILY]: {
      ...state,
      stepper: false,
      family: payload,
      editPerson: null,
      newPerson: null,
      loading: false,
      updatedPerson: false,
      parentAdded: false,
      spouseAdded: false,
      siblingAdded: false,
      childAdded: false,
      imageLoading: false,
      profileImageAdded: false,
      error: null,
      parentsAdded: false,
      refetchTree: false,
      isGedcomUploaded: false,
    },
    [CLEAR_FAMILY]: {
      ...state,
      treePersonOptions: [],
      loading: false,
      family: null,
      startTree: null,
      stepper: true,
      error: null,
      homePersonModal: false,
      treeProgressPayload: null,
      notFoundError: false
    },
    [FAMILY_ERROR]: {
      ...state,
      error: payload,
      loading: false,
      family: state.oldFamily,
      closeModalStatus: false,
    },
    [SAVE_PARENTS]: {
      ...state,
      parentsAdded: true,
    },
    [ADD_PARENT_VIA_PLACEHOLDER]: {
      ...state,
      parentAddedViaPlaceholder: false,
      personLoading: false,
      showAddGrandParentModal: null,
      restrictFirstHintHighlight: true,
    },
    [SAVE_PARENT_VIA_PLACEHOLDER]: {
      ...state,
      parentAddedViaPlaceholder: true,
      newPerson: null,
    },
    [ADD_RELATED_EVENT]: {
      ...state,
      dropDownPayload: payload,
    },
    [DIRECT_CHILDREN]: {
      ...state,
      directChildren: payload,
    },
    [GET_TREES]: {
      ...state,
      trees: payload,
      treesloading: false,
    },
    [TREES_LOADING]: {
      ...state,
      treesloading: true,
    },
    [TREE_ERROR]: {
      ...state,
      loading: false,
      startTree: null,
      homePersons: [],
      selectedHomePerson: null,
      progress: 0,
      importStatus: null,
      assigned: null,
    },
    [AUTOCOMPLETE_REQUEST]: {
      ...state,
      options: [],
      optionLoading: true,
    },
    [AUTOCOMPLETE_TEST]: {
      ...state,
      options: payload,
      optionLoading: false,
      hasNextPage: true,
    },
    [AUTOCOMPLETE_BIRTH_TEST]: {
      ...state,
      birthPlaceOptions: payload,
      optionLoading: false,
      hasNextPage: true,
    },
    [CLEAR_BIRTH_OPTIONS]: {
      ...state,
      birthPlaceOptions: [],
      optionLoading: true,
    },
    [CLEAR_DEATH_OPTIONS]: {
      ...state,
      deathPlaceOptions: [],
      optionLoading: true,
    },
    [CLEAR_RESIDENCE_OPTIONS]: {
      ...state,
      options: [],
      optionLoading: false,
    },
    [AUTOCOMPLETE_DEATH_TEST]: {
      ...state,
      deathPlaceOptions: payload,
      optionLoading: false,
      hasNextPage: true,
    },
    [AUTOCOMPLETE_ERROR]: {
      ...state,
      options: [],
      optionLoading: false,
    },
    [AUTOCOMPLETE_PAGINATION_REQUEST]: {
      ...state,
      isNextPageLoading: true,
    },
    [AUTOCOMPLETE_PAGINATION_TEST]: {
      ...state,
      options: state.options.concat(payload),
      isNextPageLoading: false,
    },
    [AUTOCOMPLETE_BIRTH_PAGINATION_TEST]: {
      ...state,
      birthPlaceOptions: state.birthPlaceOptions.concat(payload),
      isNextPageLoading: false,
    },
    [AUTOCOMPLETE_PAGINATION_ERROR]: {
      ...state,
      isNextPageLoading: false,
    },
    [SAVE_PROFILE_IMAGE]: {
      ...state,
      imageLoading: false,
      profileImageAdded: true,
      showImageCropper: false,
      originalImage: null,
      petProfileImageDeleted: false,
    },
    [SAVE_HERO_IMAGE]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
      heroImageAdded: true,
      originalHeroImage: null,
      petBGImageDeleted: false,
    },
    [DELETE_PET_PROFILE_IMAGE]: {
      ...state,
      imageLoading: false,
      petProfileImageDeleted: true,
      showImageCropper: false,
      originalImage: null,
      profileImageAdded: false,
    },
    [DELETE_PET_BG_IMAGE]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
      petBGImageDeleted: true,
      originalHeroImage: null,
      heroImageAdded: false,
    },
    [THUMBNAIL_UPLOAD_COMPLETE]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
    },
    [CLEAR_IMAGE]: {
      ...state,
      imageLoading: false,
      profileImageAdded: false,
      showImageCropper: false,
      originalImage: null,
      heroImageAdded: false,
      originalHeroImage: null,
    },
    [GET_PROFILE_IMAGE]: {
      ...state,
      originalImage: payload,
      profileImageAdded: false,
      imageFetching: false,
    },
    [GET_HERO_IMAGE]: {
      ...state,
      originalHeroImage: payload,
      profileImageAdded: false,
      imageFetching: false,
    },

    [SHOW_IMAGE]: {
      ...state,
      imageLoading: false,
      profileImageAdded: false,
      showImageCropper: true,
    },
    [TREE_PERSON_SEARCH_OPTIONS]: {
      ...state,
      treePersonOptions: payload,
      checkRefetchFamily: false,
    },
    [REFETCH_PAGE_AFTER_UPLOAD]: {
      ...state,
      profileImageAdded: true,
    },
    [CLEAR_EVENT_INFO]: {
      ...state,
      dropDownPayload: null,
      directChildren: null,
    },
    [SAVING_MODAL]: {
      ...state,
      savingModal: true,
    },
    [SAVED_MODAL]: {
      ...state,
      savingModal: false,
      newPerson: null,
      editPerson: null,
    },
    [DELETE_PERSON_LOADING]: {
      ...state,
      deletingPerson: true,
    },

    [DELETE_PERSON_LOADED]: {
      ...state,
      refetchTreePostDeletetion: payload,
      deletingPerson: false,
    },
    [DELETE_PET_LOADING]: {
      ...state,
      deletingPerson: true,
    },
    [DELETE_PET_LOADED]: {
      ...state,
      deletingPerson: false,
    },
    [REFETCH_FAMILY_INFO]: {
      ...state,
      refetchFamily: true,
    },
    [REFETCH_FAMILY_INFO_COMPLETE]: {
      ...state,
      deletingPerson: false,
      refetchFamily: false,
      refetchTreePostDeletetion: false,
    },
    [CLEAR_REFETCH_TREE_FLAG]: {
      ...state,
      refetchTreePostDeletetion: false,
    },
    [SET_HOMEPERSON_MODAL]: {
      ...state,
      homePersonModal: payload,
      family: null,
    },
    [SET_HOMEPERSON]: {
      ...state,
      homePersonModal: false,
      family: payload,
    },
    [CLOSE_HOMEPERSON_MODAL]: {
      ...state,
      homePersonModal: false,
    },
    [CLEAR_IS_PRIVATE_TREE]: {
      ...state,
      privateTree: null,
      highlightNewNode: null,
    },
    [IS_PRIVATE_TREE]: {
      ...state,
      loading: false,
      privateTree: payload,
    },
    [TREE_PROGRESS_PAYLOAD]: {
      ...state,
      refetchTreePanel: false,
      treeProgressPayload: payload,
    },
    [REFETCH_TREE_PROGRESS]: {
      ...state,
      refetchTreePanel: true,
    },
    [SHOW_TREE_CELEBRATORY_MODAL]: {
      ...state,
      showTreeCelebratoryModal: true,
    },
    [CLOSE_TREE_PROGRESS_MODAL]: {
      ...state,
      showTreeCelebratoryModal: false,
      treeProgressPayload: null,
    },
    [NEW_NODE_HIGHTLIGHT]: {
      ...state,
      highlightNewNode: payload,
    },
    [COLLECTIONID_HIGHEST_HINT]: {
      ...state,
      collectionIdOfHighestHint: payload,
    },
    [SELECTED_PARENT_SIDE]: {
      ...state,
      selectedParentSide: payload,
    },
    [UPDATE_SELECTED_PARENT_SIDE]: {
      ...state,
      selectedParentSide: null,
      showAddGrandParentModal: null,
    },
    [SHOW_ADD_GRANDPARENT_MODAL]: {
      ...state,
      showAddGrandParentModal: payload,
    },
    [CLOSE_ADD_GRANDPARENT_MODAL]: {
      ...state,
      showAddGrandParentModal: null,
    },
    [REQUEST_HINT_GENERATION.REQUEST]: {
      ...state,
    },
    [REQUEST_HINT_GENERATION.SUCCESS]: {
      ...state,
    },
    [REQUEST_HINT_GENERATION.FAILURE]: {
      ...state,
    },
    [UPDATE_HINT_COUNT_ITEM]: {
      ...state,
      hintsCountPayload: {
        ...state.hintsCountPayload,
        [payload?.familyId]: {
          ...state.hintsCountPayload?.[payload?.familyId],
          [payload?.personAndHintCount?.personId]: payload?.personAndHintCount,
        },
      },
    },
    [GET_EDITED_FAMILY]: {
      ...state,
      updatedPerson: true,
      checkRefetchFamily: true,
    },
    [SET_MODAL_DATA]: {
      ...state,
      parentAdded: false,
      parentAddedViaPlaceholder: false,
      personLoading: false,
      editPerson: null,
      newPerson: payload,
      restrictFirstHintHighlight: true,
    },
    [SET_EVENT_MODAL_DATA]: {
      ...state,
      eventDetails: payload,
    },
    [ADD_PARENT]: {
      ...state,
      dropDownPayload: payload,
    },
    [ADD_SPOUSE]: {
      ...state,
      spouseAdded: false,
      personLoading: false,
      editPerson: null,
      dropDownPayload: payload,
    },
    [ADD_SIBLING]: {
      ...state,
      dropDownPayload: payload,
    },
    [ADD_CHILD]: {
      ...state,
      childAdded: false,
      personLoading: false,
      editPerson: null,
      dropDownPayload: payload,
    },
    [SAVE_PARENT]: {
      ...state,
      parentAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_SPOUSE]: {
      ...state,
      spouseAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_SIBLING]: {
      ...state,
      siblingAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_CHILD]: {
      ...state,
      childAdded: true,
      newPerson: null,
      family: payload ? null : state.family,
      dropDownPayload: null,
      checkRefetchFamily: payload,
      closeModalStatus: true,
    },
    [SAVE_ERROR]: {
      ...state,
      error: payload,
      loading: false,
      closeModalStatus: true,
      refetchTree: true,
      savingModal: false,
    },
    [ADD_FAMILY]: {
      ...state,
      personLoading: false,
      editPerson: null,
      newPerson: payload,
    },
    [CANCEL_MODAL]: {
      ...state,
      personLoading: false,
      editPerson: null,
      newPerson: null,
      updatedPerson: false,
      parentAdded: false,
      parentAddedViaPlaceholder: false,
      spouseAdded: false,
      siblingAdded: false,
      childAdded: false,
      profileImageAdded: false,
      dropDownPayload: null,
      birthPlaceOptions: [],
      deathPlaceOptions: [],
      closeModalStatus: false,
      restrictFirstHintHighlight: false,
    },
    [GET_FAMILY]: {
      ...state,
      stepper: false,
      family: payload,
      refetchTreePanel: true,
      oldFamily: payload,
      newPerson: null,
      loading: false,
      updatedPerson: false,
      parentAdded: false,
      parentAddedViaPlaceholder: false,
      spouseAdded: false,
      siblingAdded: false,
      childAdded: false,
      imageLoading: false,
      profileImageAdded: false,
      error: null,
      parentsAdded: false,
      checkRefetchFamily: false,
      closeModalStatus: false,
      refetchTree: false,
      refetchTreePostDeletetion: false,
      pendingNotificationSnackbar: null,
    },
    [UPDATE_FAMILY]: {
      ...state,
      stepper: false,
      family: payload,
      editPerson: null,
      newPerson: null,
      loading: false,
      updatedPerson: false,
      parentAdded: false,
      spouseAdded: false,
      siblingAdded: false,
      childAdded: false,
      imageLoading: false,
      profileImageAdded: false,
      error: null,
      parentsAdded: false,
      refetchTree: false,
      isGedcomUploaded: false,
    },
    [CLEAR_FAMILY]: {
      ...state,
      treePersonOptions: [],
      loading: false,
      family: null,
      startTree: null,
      stepper: true,
      error: null,
      homePersonModal: false,
      treeProgressPayload: null,
      notFoundError: false
    },
    [FAMILY_ERROR]: {
      ...state,
      error: payload,
      loading: false,
      family: state.oldFamily,
      closeModalStatus: false,
    },
    [SAVE_PARENTS]: {
      ...state,
      parentsAdded: true,
    },
    [ADD_PARENT_VIA_PLACEHOLDER]: {
      ...state,
      parentAddedViaPlaceholder: false,
      personLoading: false,
      showAddGrandParentModal: null,
      restrictFirstHintHighlight: true,
    },
    [SAVE_PARENT_VIA_PLACEHOLDER]: {
      ...state,
      parentAddedViaPlaceholder: true,
      newPerson: null,
    },
    [ADD_RELATED_EVENT]: {
      ...state,
      dropDownPayload: payload,
    },
    [DIRECT_CHILDREN]: {
      ...state,
      directChildren: payload,
    },
    [GET_TREES]: {
      ...state,
      trees: payload,
      treesloading: false,
    },
    [TREES_LOADING]: {
      ...state,
      treesloading: true,
    },
    [TREE_ERROR]: {
      ...state,
      loading: false,
      startTree: null,
      homePersons: [],
      selectedHomePerson: null,
      progress: 0,
      importStatus: null,
      assigned: null,
    },
    [AUTOCOMPLETE_REQUEST]: {
      ...state,
      options: [],
      optionLoading: true,
    },
    [AUTOCOMPLETE_TEST]: {
      ...state,
      options: payload,
      optionLoading: false,
      hasNextPage: true,
    },
    [AUTOCOMPLETE_BIRTH_TEST]: {
      ...state,
      birthPlaceOptions: payload,
      optionLoading: false,
      hasNextPage: true,
    },
    [CLEAR_BIRTH_OPTIONS]: {
      ...state,
      birthPlaceOptions: [],
      optionLoading: true,
    },
    [CLEAR_DEATH_OPTIONS]: {
      ...state,
      deathPlaceOptions: [],
      optionLoading: true,
    },
    [CLEAR_RESIDENCE_OPTIONS]: {
      ...state,
      options: [],
      optionLoading: false,
    },
    [AUTOCOMPLETE_DEATH_TEST]: {
      ...state,
      deathPlaceOptions: payload,
      optionLoading: false,
      hasNextPage: true,
    },
    [AUTOCOMPLETE_ERROR]: {
      ...state,
      options: [],
      optionLoading: false,
    },
    [AUTOCOMPLETE_PAGINATION_REQUEST]: {
      ...state,
      isNextPageLoading: true,
    },
    [AUTOCOMPLETE_PAGINATION_TEST]: {
      ...state,
      options: state.options.concat(payload),
      isNextPageLoading: false,
    },
    [AUTOCOMPLETE_BIRTH_PAGINATION_TEST]: {
      ...state,
      birthPlaceOptions: state.birthPlaceOptions.concat(payload),
      isNextPageLoading: false,
    },
    [AUTOCOMPLETE_PAGINATION_ERROR]: {
      ...state,
      isNextPageLoading: false,
    },
    [SAVE_PROFILE_IMAGE]: {
      ...state,
      imageLoading: false,
      profileImageAdded: true,
      showImageCropper: false,
      originalImage: null,
      petProfileImageDeleted: false,
    },
    [SAVE_HERO_IMAGE]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
      heroImageAdded: true,
      originalHeroImage: null,
      petBGImageDeleted: false,
    },
    [DELETE_PET_PROFILE_IMAGE]: {
      ...state,
      imageLoading: false,
      petProfileImageDeleted: true,
      showImageCropper: false,
      originalImage: null,
      profileImageAdded: false,
    },
    [DELETE_PET_BG_IMAGE]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
      petBGImageDeleted: true,
      originalHeroImage: null,
      heroImageAdded: false,
    },
    [THUMBNAIL_UPLOAD_COMPLETE]: {
      ...state,
      imageLoading: false,
      showImageCropper: false,
    },
    [CLEAR_IMAGE]: {
      ...state,
      imageLoading: false,
      profileImageAdded: false,
      showImageCropper: false,
      originalImage: null,
      heroImageAdded: false,
      originalHeroImage: null,
    },
    [GET_PROFILE_IMAGE]: {
      ...state,
      originalImage: payload,
      profileImageAdded: false,
      imageFetching: false,
    },
    [GET_HERO_IMAGE]: {
      ...state,
      originalHeroImage: payload,
      profileImageAdded: false,
      imageFetching: false,
    },

    [SHOW_IMAGE]: {
      ...state,
      imageLoading: false,
      profileImageAdded: false,
      showImageCropper: true,
    },
    [TREE_PERSON_SEARCH_OPTIONS]: {
      ...state,
      treePersonOptions: payload,
      checkRefetchFamily: false,
    },
    [REFETCH_PAGE_AFTER_UPLOAD]: {
      ...state,
      profileImageAdded: true,
    },
    [CLEAR_EVENT_INFO]: {
      ...state,
      dropDownPayload: null,
      directChildren: null,
    },
    [SAVING_MODAL]: {
      ...state,
      savingModal: true,
    },
    [SAVED_MODAL]: {
      ...state,
      savingModal: false,
      newPerson: null,
      editPerson: null,
    },
    [DELETE_PERSON_LOADING]: {
      ...state,
      deletingPerson: true,
    },

    [DELETE_PERSON_LOADED]: {
      ...state,
      refetchTreePostDeletetion: payload,
      deletingPerson: false,
    },
    [DELETE_PET_LOADING]: {
      ...state,
      deletingPerson: true,
    },
    [DELETE_PET_LOADED]: {
      ...state,
      deletingPerson: false,
    },
    [REFETCH_FAMILY_INFO]: {
      ...state,
      refetchFamily: true,
    },
    [REFETCH_FAMILY_INFO_COMPLETE]: {
      ...state,
      deletingPerson: false,
      refetchFamily: false,
      refetchTreePostDeletetion: false,
    },
    [CLEAR_REFETCH_TREE_FLAG]: {
      ...state,
      refetchTreePostDeletetion: false,
    },
    [SET_HOMEPERSON_MODAL]: {
      ...state,
      homePersonModal: payload,
      family: null,
    },
    [SET_HOMEPERSON]: {
      ...state,
      homePersonModal: false,
      family: payload,
    },
    [CLOSE_HOMEPERSON_MODAL]: {
      ...state,
      homePersonModal: false,
    },
    [CLEAR_IS_PRIVATE_TREE]: {
      ...state,
      privateTree: null,
      highlightNewNode: null,
    },
    [IS_PRIVATE_TREE]: {
      ...state,
      loading: false,
      privateTree: payload,
    },
    [TREE_PROGRESS_PAYLOAD]: {
      ...state,
      refetchTreePanel: false,
      treeProgressPayload: payload,
    },
    [REFETCH_TREE_PROGRESS]: {
      ...state,
      refetchTreePanel: true,
    },
    [SHOW_TREE_CELEBRATORY_MODAL]: {
      ...state,
      showTreeCelebratoryModal: true,
    },
    [CLOSE_TREE_PROGRESS_MODAL]: {
      ...state,
      showTreeCelebratoryModal: false,
      treeProgressPayload: null,
    },
    [NEW_NODE_HIGHTLIGHT]: {
      ...state,
      highlightNewNode: payload,
    },
    [SELECTED_PARENT_SIDE]: {
      ...state,
      selectedParentSide: payload,
    },
    [UPDATE_SELECTED_PARENT_SIDE]: {
      ...state,
      selectedParentSide: null,
      showAddGrandParentModal: null,
    },
    [SHOW_ADD_GRANDPARENT_MODAL]: {
      ...state,
      showAddGrandParentModal: payload,
    },
    [CLOSE_ADD_GRANDPARENT_MODAL]: {
      ...state,
      showAddGrandParentModal: null,
    },
    [REQUEST_HINT_GENERATION.REQUEST]: {
      ...state,
    },
    [REQUEST_HINT_GENERATION.SUCCESS]: {
      ...state,
    },
    [REQUEST_HINT_GENERATION.FAILURE]: {
      ...state,
    },
    [GETRIGHTPANELDETAILS_SUCCESS]: {
      ...state,
      rightPanelDetails: payload,
    },
    [NOT_FOUND_ERROR]: {
      ...state,
      notFoundError: payload,
    }
  };
  return mapping[type] || state;
};

export default family;
